@import url(//db.onlinewebfonts.com/c/7cc6719bd5f0310be3150ba33418e72e?family=Comic+Sans+MS);
@import url(https://allfont.net/allfont.css?fonts=comic-sans-ms-bold);
* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0px;
  padding: 0px;
  font-family: comic sans Ms;
}
@media only screen and (min-width: 320px) and (max-width: 576px) {
  .content {
    width: 100%;
    padding-left: 7rem;
    flex-direction: column-reverse;
  }
}
.content {
  padding-left: 7rem;
  overflow-y: auto;
  height: 90vh;
}
