.FAQ_wrapper {
  padding-left: 7rem;
  height: 90vh;
  background-image: url("/public/PrimeStudentImages/Myprofile_Bg_Icon.svg");
}
.FAQ_wrapper h5 {
  padding-top: 2rem;
  color: #111111;
  font-size: 23px;
  padding-bottom: 1rem;
  padding-left: 0.2rem;
}
.accordion .accordion-item {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 6px;
  margin: 0.5rem 0rem 0px 0rem;
  font-size: 14px;
  width: 96%;
}
.accordion h6 {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  flex-direction: column;
  width: 100%;
  height: 50px;
  /* padding: 0.3rem 3rem 0.1rem 1rem; */
  padding-top: 0.5rem;
  padding-left: 2rem;
  padding-right: 1rem;
  color: #111;
  font-size: 14px;
  font-weight: 400;
  /* border-bottom: 1px solid #e5e5e5; */
}

.accordion h6:hover,
.accordion h6:hover::after {
  cursor: pointer;
  color: #111;
}

/* .accordion h6:hover::after {
  border: 1px solid #03b5d2;
}

.accordion h6.active {
  color:#111;
  border-bottom: 1px solid #03b5d2;
} */
.accordion h6 i {
  display: inline;
  position: absolute;
  float: right;
  right: 1rem;
  color: #8b60fd;
  font-size: 18px;
  font-weight: 900;
  top: 0.7rem;
}

/* .accordion h6::after {
  font-family: "Ionicons";
  content: "\002B";
  position: absolute;
  float: right;
  right: 1rem;
  font-size: 1rem;
  color:#8B60FD;
  font-size: 25px;
  font-weight: 900;
  padding-bottom: 20px;
  width: 30px;
  height: 30px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  border: 1px solid #7288a2;
  text-align: center;
} */
/* .accordion h6 i.active::after {
  font-family: "Ionicons";
  content: "\002D";
  color: #8b60fd;
  font-size: 18px;
  font-weight: 900;
} */
.accordion .content {
  display: none;
  padding: 1rem 2rem 0rem 2rem;
  border-bottom: 1px solid #e5e5e5;
  overflow: hidden;
  height: 80px;
}
.accordion .content p {
  font-size: 14px;
  font-weight: 300;
}
.faq_textarea {
  text-align: center;
}
.faq_textarea h6 {
  color: #111;
  font-size: 14px;
  padding-top: 1rem;
}
.faq_textarea h6 a {
  color: #8b60fd;
}
.faq_textarea textarea {
  width: 93%;
  margin-left: 1rem;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #8b60fd;
  border-radius: 4px;
  margin-top: 1rem;
  font-size: 14px;
}
.querry_button button {
  border: 1px solid #fafafa;
  border-radius: 49px;
  background-color: #ff6700;
  color: #fff;
  font-size: 12px;
  padding: 0.5rem 1rem;
  margin-top: 1rem;
}
.faq_right_side img{
  float: right;
  margin-right: 3rem;
}