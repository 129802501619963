/* login page css */
.signin_wrapper {
  background-color: #fff;
  height: 100vh;
}
.main-banner {
  margin: 0 auto;
  width: 85%;
}
.top-p {
  position: relative;
}
.login-banner img {
  background-size: cover;
  background-repeat: no-repeat;
  overflow: hidden;
  width: 85%;
  height: 100%;
  position: relative;
  top: 85px;
  left: 30px;
}
.Logo img {
  height: 135px;
  width: 135px;
  left: 90px;
  position: absolute;
  top: 25px;
}
@media screen and (max-width: 320px) {
  .login-banner {
    height: 350px;
  }
}
.right-content {
  text-align: left;
  padding-top: 145px;
  width: 350px;
  margin-left: 100px;
  padding-right: 10px;
}
.signin {
  padding-top: 20px;
}
.signin h1 {
  font-size: 1.2rem;
  font-weight: 600;
}
.signin p {
  text-align: left;
  font: normal normal 600 30px/38px Poppins;
  letter-spacing: 0.2px;
  color: #020723;
  opacity: 1;
}
.p2 p {
  text-align: left;
  font: normal normal normal 14px/18px Poppins;
  letter-spacing: 0px;
  color: #020723;
  opacity: 0.87;
  margin-bottom: 5px;
}
.p3 p {
  text-align: left;
  font: normal normal normal 10px/16px Poppins;
  letter-spacing: 0.4px;
  color: #020723;
  opacity: 0.7;
  margin-bottom: 5px;
}
.p4 .label_signin {
  font: normal normal medium 18px/27px Poppins;
  letter-spacing: 0px;
  color: #020723;
  opacity: 0.87;
  text-align: left;
  padding-left: 6.2rem;
  padding-right: 1rem;
}
.form-check-label {
  padding-top: 4px;
  padding-left: 8px;
}
.form-check-input {
  border: 3px solid #8b60fd;
  /* top: 455px; */
  /* left: 1213px; */
  width: 20px;
  height: 20px;
  opacity: 1;
}
.form-check-input:checked {
  background-color: #8b60fd;
  border-color: #8b60fd;
}
.form-check-input:focus,
.btn-check:focus + .btn-primary,
.btn-primary:focus {
  box-shadow: none !important;
}
#inlineRadio1:hover {
  background-color: #8b60fd;
}
#inlineRadio2:hover {
  background-color: #8b60fd;
}
.student-form {
  padding-left: 6rem;
  padding-top: 2rem;
}
.Signin-Button {
  padding-left: 6rem;
}
.Student-Label {
  top: 508px;
  left: 106px;
  width: 131px;
  height: 26px;
  text-align: left;
  font: normal normal normal 14px/28px Poppins;
  letter-spacing: 0.14px;
  color: #020723;
  opacity: 1;
}
.Student-inputfield {
  width: 350px;
  height: 45px;
  background: #f2f7ff 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
}
.student-form input[type="text"] {
  width: 350px;
  height: 42px;
  background: #f2f7ff 0% 0% no-repeat padding-box;
  border: #f2f7ff;
  border-radius: 8px;
  border: 1px solid #8b60fd;
  position: relative;
  color: #a9a9a9;
  font-size: 12px;
}
.student-form input[type="password"] {
  width: 350px;
  height: 42px;
  background: #f2f7ff 0% 0% no-repeat padding-box;
  border: #f2f7ff;
  border-radius: 8px;
  color: #a9a9a9;
  border: 1px solid #8b60fd;
  position: relative;
  font-size: 12px;
}
.invalid-error {
  border: 1px solid #f8364f;
}
.Signin-Button button {
  width: 350px;
  height: 42px;
  background: #daccff 0% 0% no-repeat padding-box;
  border-radius: 8px;
  border: #8b60fd;
  color: #ffffff;
  opacity: 0.87;
}
.student-form .form-group {
  margin-bottom: 15px;
  position: relative;
}
.student-form .form-group label {
  margin-bottom: 5px;
  font-weight: 400;
}

.error-msg {
  text-align: left;
  font: normal normal 600 12px/26px Poppins;
  letter-spacing: 0px;
  color: #f82641;
}
.error-msg span i {
  color: #f82641;
}
.pr-2 {
  padding-right: 6px;
}
.forgot {
  float: right;
  text-align: right;
  font-size: 12px;
  letter-spacing: 0.47px;
  color: #0b92e8;
  text-decoration: none;
  padding-top: 5px;
  padding-right: 11rem;
}

.eye-visible i {
  position: absolute;
  top: 2rem;
  right: 12rem;
  color: #8b60fd;
  font-size: 18px;
}
.eye-visible svg {
  fill: #8b60fd;
}
/* SignOut CSS */
.signout-card {
  margin: 0 auto;
  width: 550px;
  height: 18rem;
  z-index: 2;
  border-radius: 49px;
  padding: 30px;
  position: absolute;
  top: 250px;
  left: 30%;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 10px solid #8b60fd;
}
.signout-card h6 {
  color: #ff6700;
  font-size: 22px;
  text-align: center;
}
.signout-btn {
  text-align: center;
}
.signout-btn button {
  width: 100px;
  height: 35px;
  border: none;
  color: white;
  border-radius: 49px;
  font-size: 20px;
  margin: 1rem;
}
.signout-btn .button1 {
  background-color: #ff6700;
}
.signout-btn .button2 {
  background-color: #999999;
  border: 1px solid #fafafa;
}
