.pagination a.correct {
  background-color: #2bb10d;
  border: 2px solid #2bb10d;
  color: white;
}

.Selftest_Options_Solution button {
  margin-bottom: 1rem;
  margin-right: 4rem;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 12px 19px #225a6f1a;
  border: 1px solid #b4ad9f;
  border-radius: 28px;
  font-size: 18px;
  width: 20%;
  height: 38px;
  text-align: left;
  padding-left: 2rem;
  color: #111111;
}
#Correct_Answer {
  background: #a5f4c6 0% 0% no-repeat padding-box;
  box-shadow: 0px 12px 19px #225a6f1a;
  border: 1px solid #46b382;
}
.Close_Button_Selftest_botton {
  margin-top: 2.5rem;
}
#solution button {
  background: #ff6700 0% 0% no-repeat padding-box;
  border: 2px solid #ff6700;
  border-radius: 49px;
  font-size: 15px;
  font-weight: bold;
  color: #ffffff;
  padding: 0px 10px;
  margin-left: 0.5rem;
}
.Close_Button_Selftest_botton img {
  width: 7rem;
  margin-left: 35rem;
  align-items: center;
  position: relative;
  top: -2.2rem;
}
.Close_Button_Selftest_botton #Next {
  margin-left: 15rem;
  background: #39311c 0% 0% no-repeat padding-box;
  border-radius: 49px;
  color: #ffffff;
  font-size: 18px;
  padding: 0px 10px;
  opacity: 1;
}
.Selftest_bottom_Chapter span {
  font-size: 10px;
  color: #111111;
  margin-top: 0.5rem;
}
.Solution_View {
  background-color: #c6e4ce;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  z-index: 2;
  padding: 0px;
  position: absolute;
  top: 0%;
  right: 0%;
}

.Solution_Button button {
  margin-left: 4.2rem;
  background-color: #fef9ef;
  border: 3px solid #e97838;
  font: normal normal bold 25px/42px Comic Sans MS;
  border-radius: 15px;
  letter-spacing: 0px;
  color: #064974;
  padding: 10px 40px;
  position: relative;
  top: 7.5rem;
  border-bottom: none;
}
.Theory_Button button {
  margin-left: 50.5rem;
  background-color: #fef9ef;
  border: 3px solid #e97838;
  font: normal normal bold 25px/42px Comic Sans MS;
  border-radius: 15px;
  letter-spacing: 0px;
  color: #064974;
  padding: 10px 40px;
  position: relative;
  top: 7.5rem;
  border-bottom: none;
}
.Solution_Buttons {
  background-color: #fef9ef;
  margin: 7rem;
  padding: 4rem 5rem 3rem 6rem;
  border: 3px solid #e97838;
  border-radius: 40px;
  text-align: justify;
}
.Solution_Tab_Data p, h6{
  /* text-align: left; */
  color:#918E96;
  font: normal normal bold 25px/42px Comic Sans MS;
}
