body {
  margin: 0px;
  padding: 0px;
  overflow: hidden;
}
a {
  text-decoration: none;
}
@media only screen and (min-width: 320px) and (max-width: 576px) {
  .aside {
    width: 100%;
    padding-left: 23px;
    flex-direction: column-reverse;
  }
}
.aside {
  position: absolute;
  top: 0;
  background-color: #ffffff;
  box-shadow: 0px 3px 6px #00000029;
  width: 100px;
  height: 100vh;
}
.navbar-brand {
  text-align: left;
  position: relative;
  top: 25px;
  right: 5px;
}
.navbar-brand img {
  width: 37px;
  height: 67px;
  position: relative;
  top: -10px;
}
.Sidebar_Icons {
  margin-top: 15%;
}
.Sidebar_Icons ul li span {
  position: absolute;
}
.Sidebar_Icons ul li {
  list-style-type: none;
  padding-top: 45px;
  font-size: 12px;
  /* padding-left: 5px; */
  /* padding-right: 10px;
  text-align: left; */
}
.Sidebar_Icons ul li span img {
  width: 20px;
  height: 20px;
  /* text-align:left; */
}
.Sidebar_Icons ul li:hover img {
  transform: scale(0);
}
.Sidebar_Icons ul li.active img {
  transform: scale(0);
  margin-right: 10px;
}
.Sidebar_Icons ul li .title {
  /* display: none; */
  font-size: 11px;
  color: #8b60fd;
  transform: scale(0);
  text-align: left;
  /* transition: 0.5s; */
  transition-delay: 0s;
  font-weight: bold;
  text-align: left;
  margin-right: 10px;
}
.Sidebar_Icons ul li:hover .title {
  display: flex;
  transform: scale(1);
  text-align: left;
  /* padding-right: 10px; */
  /* transition-delay: 0.25s; */
}
.Sidebar_Icons ul li.active .title {
  display: flex;
  transform: scale(1);
  /* padding-right: 60px; */
  text-align: left;
  /* transition-delay: 0.25s; */
}
/* Topbar CSS */
@media only screen and (min-width: 320px) and (max-width: 576px) {
  .navbar {
    width: 100%;
    padding-left: 23px;
    flex-direction: column-reverse;
  }
}
.navbar {
  height: 70px;
  margin-left: 100px;
  width: 100%;
  background: #8b60fd 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 4px #0000001a;
}
@media (max-width: 300px) {
  .navbar {
    height: 70px;
    margin-left: 100px;
    width: 100%;
    background: #8b60fd 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 4px #0000001a;
    transform: translateY(100px);
    transition: all 300ms ease-out;
  }
}
.Right-Side-align {
  align-items: center;
  float: right;
  padding-left: 25%;
}
/*Topbar Notification CSS */
.Notifications {
  padding-right: 30px;
}
.Notifications img {
  width: 15px;
  height: 20px;
}
/*Topbar Badges Css */
.Badges {
  padding-right: 30px;
}
.Badges img {
  width: 16px;
  height: 20px;
}
/*Topbar Profile Css */
.profile-dropdown {
  float: right;
  padding-left: 49px;
}
.profile-dropdown li {
  display: inline-block;
}
.Profile img {
  position: relative;
  width: 40px;
  height: 40px;
  top: 20px;
}
.Profile,
.dropdown {
  margin-left: 10px;
}
.myprofile_top_data p {
  color: #fff !important;
  top: 15px;
  position: relative;
  font-size: 12px;
}
.myprofile_top_data span {
  position: relative;
  color: #ffffff;
  right: 0px;
  bottom: 1px;
  font-size: 8px;
}

.nav-link:hover,
.nav-link:focus {
  color: #fff;
  box-shadow: 5px white;
}
.dropdown span {
  font-size: 12px;
}
.dropdown:hover .top-user {
  display: block;
  background-color: #ffffff;
  right: -23px;
  /* left: 3px; */
  border: 1px solid #0b92e866;
  top: 75px;
  box-shadow: 0px 3px 6px #00000029;
  text-align: center;
  align-items: center;
  width: 250px;
  height: 210px;
  padding: 0px;
}
.profile-dropdown .top-user a {
  text-decoration: none;
  padding: 0px;
}
.profile_dropdown_image {
  padding: 10px 10px 10px 10px;
}
.profile_dropdown_image img {
  width: 80px;
  position: relative;
  left: 70px;
}
#profile_Imag{
  border-radius: 50%;
  border: 3px solid #0B92E8;
}
.profile_dropdown_image #close_icon img {
  float: right;
  position: relative;
  left: 110px;
  /* background-color: #fdf980;
  box-shadow: 3px 3px 3px #00000029;
  border: 2px solid #fafafa; */
  height: 25px;
  width: 30px;
}
.profile_dropdown_image a {
  text-decoration: none;
  color: #111111;
}
.Profile_View {
  border-bottom: 1px solid #00000029;
  padding-top: 10px;
  padding-bottom: 10px;
  letter-spacing: 0px;
  line-height: 1px;
}
.Profile_View p {
  font-size: 14px;
  color: #111111;
}
.Profile_View button {
  color: #8b60fd;
  border: none;
  background-color: #fff;
  font-size: 12px;
}
.SignOut_Page {
  margin-top: 10px;
  background-color: #ff6700;
  border-radius: 49px;
  width: 80px;
  margin-left: 35%;
  padding-top: 3px;
  padding-bottom: 5px;
}
.SignOut_Page button {
  border-color: #fafafa;
  background-color: #ff6700;
  padding: 2px 10px 2px 10px;
  border-radius: 49px;
  color: #fff;
  font-size: 12px;
  border: 1px solid #fafafa;
}
