.SeasonActivity_Wrapper {
  padding-left: 7rem;
  padding-top: 1rem;
  background-color: #ffffff;
  height: auto;
}
.SeasonActivity_Dropdown select {
  border: 1px solid #e97838;
  border-radius: 8px;
  width: 9.5rem;
  color: #e97838;
  font-size: 18px;
  padding-left: 10px;
  padding-right: 30px;
}
.SeasonActivity_Dropdown select:hover {
  background-color: #e97838;
  color: #fff;
}
.SeasonActivity_Dropdown option {
  color: #fff;
  font-size: 16px;
  padding: 100px;
}
.SeasonActivity_Dropdown #math {
  background-color: #e97838;
  border-radius: 13px;
  padding: 40px;
}
.SeasonActivity_Dropdown #science {
  background-color: #6deea5;
  border-radius: 13px;
}
.Sesons_data {
  margin-top: 1rem;
}
.Seasons_Buttons button {
  margin-right: 8rem;
  font-size: 15px;
  font-weight: bold;
  border: none;
  background-color: #fff;
  text-align: center;
}
.Seasons_Buttons #summer.active {
  background-color: #facd51;
  color: #111;
  width: 6rem;
  text-align: center;
  padding: 5px 1rem 5px 1rem;
  border-radius: 4px;
}
.Seasons_Buttons #autumn.active {
  background-color: #d68660;
  color: #fff;
  width: 6rem;
  text-align: center;
  padding: 5px 1rem 5px 1rem;
  border-radius: 4px;
}
.Seasons_Buttons #winter.active {
  background-color: #005484;
  color: #fff;
  width: 6rem;
  text-align: center;
  padding: 5px 1rem 5px 1rem;
  border-radius: 4px;
}
.Seasons_Buttons #spring.active {
  background-color: #95c136;
  color: #111;
  width: 6rem;
  text-align: center;
  padding: 5px 1rem 5px 1rem;
  border-radius: 4px;
}
/* .Seasons_Buttons #summer:active {
  background-color: #facd51;
  color: #111;
  width: 6rem;
  text-align: center;
  padding: 5px 1rem 5px 1rem;
  border-radius: 4px;
}
.Seasons_Buttons #summer:hover {
  background-color: #facd51;
  color: #111;
  width: 6rem;
  padding: 5px 1rem 5px 1rem;
  border-radius: 4px;
}
.Seasons_Buttons #summer:focus {
  background-color: #facd51;
  color: #111;
  width: 6rem;
  padding: 5px 1rem 5px 1rem;
  border-radius: 4px;
} */
/* .Seasons_Buttons #autumn:active {
  background-color: #d68660;
  color: #fff;
  width: 6rem;
  text-align: center;
  padding: 5px 1rem 5px 1rem;
  border-radius: 4px;
}
.Seasons_Buttons #autumn:hover {
  background-color: #d68660;
  color: #fff;
  width: 6rem;
  padding: 5px 1rem 5px 1rem;
  border-radius: 4px;
}
.Seasons_Buttons #autumn:focus {
  background-color: #d68660;
  color: #fff;
  width: 6rem;
  padding: 5px 1rem 5px 1rem;
  border-radius: 4px;
} */
/* .Seasons_Buttons #winter:active {
  background-color: #005484;
  color: #fff;
  width: 6rem;
  text-align: center;
  padding: 5px 1rem 5px 1rem;
  border-radius: 4px;
}
.Seasons_Buttons #winter:hover {
  background-color: #005484;
  color: #fff;
  width: 6rem;
  padding: 5px 1rem 5px 1rem;
  border-radius: 4px;
}
.Seasons_Buttons #winter:focus {
  background-color: #005484;
  color: #fff;
  width: 6rem;
  padding: 5px 1rem 5px 1rem;
  border-radius: 4px;
} */
/* .Seasons_Buttons #spring:active {
  background-color: #95c136;
  color: #111;
  width: 6rem;
  text-align: center;
  padding: 5px 1rem 5px 1rem;
  border-radius: 4px;
}
.Seasons_Buttons #spring:hover {
  background-color: #95c136;
  color: #111;
  width: 6rem;
  padding: 5px 1rem 5px 1rem;
  border-radius: 4px;
}
.Seasons_Buttons #spring:focus {
  background-color: #95c136;
  color: #111;
  width: 6rem;
  padding: 5px 1rem 5px 1rem;
  border-radius: 4px;
} */
.Season_top_right img {
  margin-right: 1rem;
}
.Season_top_right input {
  margin-right: 1rem;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #8b60fd;
  border-radius: 4px;
  width: 24%;
  padding-left: 1rem;
}
.Season_Activities {
  /* margin-top: 1rem; */
  padding-top: 1rem;
  padding-left: 5rem;
  background-color: #ffffff;
  height: auto;
}
/* Summer Season CSS */
.Summer_Season {
  background: transparent linear-gradient(180deg, #facd51 0%, #d6f7d2 100%) 0%
    0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  padding: 1rem 3rem 2rem 3rem;
  height: 33.5rem;
  margin-right: 0.5rem;
}
.Summer_Season_weeks button {
  border: none;
  background-color: transparent;
  margin-left: 2rem;
  margin-right: 6rem;
  color: #111111;
  opacity: 0.5;
  font-size: 16px;
}
.Summer_Season_weeks #active {
  color: #111111;
  opacity: 100%;
  font-weight: bold;
}
.Summer_Season_weeks button:active {
  color: #111111;
  opacity: 100%;
  font-weight: bold;
}
.Summer_Season_weeks button:focus {
  color: #111111;
  opacity: 100%;
  font-weight: bold;
}
.Summer_Season_weeks button:hover {
  color: #111111;
  opacity: 100%;
  font-weight: bold;
}
.Summer_Week_tabs {
  margin-top: 2rem;
}
.season_week_Test {
  background: #facd51 0% 0% no-repeat padding-box;
  border: 1px solid #facd51;
  border-radius: 12px;
  height: auto;
  width: 90%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1rem;
}
.week_tab {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #facd51;
  border-radius: 4px;
  width: 99%;
  height: auto;
  margin-bottom: 1rem;
}
.week_Name_tab h4 {
  text-align: center;
  margin-top: 1rem;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 25%;
  border: 1px solid #fafafa;
  border-radius: 49px;
  background-color: #facd51;
  font-size: 20px;
  font-weight: bold;
  padding: 0.5rem 0px 0.5rem 0px;
}
.Summer_Lottie {
  position: absolute;
  width: 8%;
  top: 18rem;
  margin-left: 1rem;
}
.Sunflower {
  width: 8%;
  position: absolute;
  right: 9rem;
  top: 33rem;
}
.Week_table_data {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 55%;
  text-align: justify;
  margin-bottom: 1.5rem;
}
.Week_table_data div {
  padding: 1rem 0rem 0.5rem 0rem;
  text-align: justify;
}
.Week_table_data div p {
  font-size: 16px;
  letter-spacing: 0px;
  color: #111111;
}
.Week_table_data div span {
  color: #707070;
  margin-left: 1rem;
  margin-right: 1rem;
}
.Week_table_data div #start {
  background: #fae751 0% 0% no-repeat padding-box;
  margin-left: 1rem;
  margin-right: 1rem;
  border: none;
  border-radius: 49px;
  padding: 0px 1rem 0px 1rem;
}
.Week_table_data div #redo {
  background: #989898 0% 0% no-repeat padding-box;
  margin-right: 1rem;
  margin-left: 1rem;
  border: none;
  border-radius: 49px;
  padding: 0px 1rem 0px 1rem;
}
.Week_table_data div #time {
  margin-right: 1rem;
  margin-left: 1rem;
  color: #111;
  background-color: transparent;
  border: none;
}
/* Autumn Season Component CSS */
.Autumn_Season {
  background: transparent linear-gradient(180deg, #ffaa7b 0%, #facd51 100%) 0%
    0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  padding: 1rem 3rem 2rem 3rem;
  height: 33.5rem;
  margin-right: 0.5rem;
}
.Autumn_Season_weeks button {
  border: none;
  background-color: transparent;
  margin-left: 2rem;
  margin-right: 6rem;
  color: #111111;
  opacity: 0.5;
  font-size: 16px;
}
.Autumn_Season_weeks #active {
  color: #111111;
  opacity: 100%;
  font-weight: bold;
}
.Autumn_Season_weeks button:active {
  color: #111111;
  opacity: 100%;
  font-weight: bold;
}
.Autumn_Season_weeks button:focus {
  color: #111111;
  opacity: 100%;
  font-weight: bold;
}
.Autumn_Season_weeks button:hover {
  color: #111111;
  opacity: 100%;
  font-weight: bold;
}
.Autumn_Week_tabs {
  margin-top: 2rem;
}
.Autumn_season_week_Test {
  background: #e87737 0% 0% no-repeat padding-box;
  border: 1px solid #e87737;
  height: auto;
  width: 90%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1rem;
  border-radius: 10px;
}
.Autumn_week_tab {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #e87839;
  border-radius: 4px;
  width: 99%;
  height: auto;
  margin-bottom: 1rem;
}
.Autumn_week_Name_tab h4 {
  text-align: center;
  margin-top: 1rem;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 25%;
  border: 1px solid #fafafa;
  border-radius: 49px;
  background-color: #e87839;
  font-size: 20px;
  font-weight: bold;
  padding: 0.5rem 0px 0.5rem 0px;
}
.Summer_Lottie {
  position: absolute;
  width: 8%;
  top: 18rem;
  margin-left: 1rem;
}
.Sunflower {
  width: 8%;
  position: absolute;
  right: 9rem;
  top: 33rem;
}
.Autumn_Week_table_data {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 55%;
  text-align: justify;
  margin-bottom: 1.5rem;
}
.Autumn_Week_table_data div {
  padding: 1rem 0rem 0.5rem 0rem;
  text-align: justify;
}
.Autumn_Week_table_data div p {
  font-size: 16px;
  letter-spacing: 0px;
  color: #111111;
}
.Autumn_Week_table_data div span {
  color: #707070;
  margin-left: 1rem;
  margin-right: 1rem;
}
.Autumn_Week_table_data div #start {
  background: #e87839 0% 0% no-repeat padding-box;
  margin-left: 1rem;
  margin-right: 1rem;
  border: none;
  border-radius: 49px;
  padding: 0px 1rem 0px 1rem;
}
.Autumn_Week_table_data div #redo {
  background: #989898 0% 0% no-repeat padding-box;
  margin-right: 1rem;
  margin-left: 1rem;
  border: none;
  border-radius: 49px;
  padding: 0px 1rem 0px 1rem;
}
.Autumn_Week_table_data div #time {
  margin-right: 1rem;
  margin-left: 1rem;
  color: #111;
  background-color: transparent;
  border: none;
}
/* Winter Season Component CSS */
.Winter_Season {
  background: transparent linear-gradient(180deg, #afc8f2 0%, #b1d0f7 100%) 0%
    0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  padding: 1rem 3rem 2rem 3rem;
  height: 33.5rem;
  margin-right: 0.5rem;
}
.Winter_Season_weeks button {
  border: none;
  background-color: transparent;
  margin-left: 2rem;
  margin-right: 6rem;
  color: #fff;
  opacity: 0.5;
  font-size: 16px;
}
.Winter_Season_weeks #active {
  color: #fff;
  opacity: 100%;
  font-weight: bold;
}
.Winter_Season_weeks button:active {
  color: #fff;
  opacity: 100%;
  font-weight: bold;
}
.Winter_Season_weeks button:focus {
  color: #fff;
  opacity: 100%;
  font-weight: bold;
}
.Winter_Season_weeks button:hover {
  color: #fff;
  opacity: 100%;
  font-weight: bold;
}
.Winter_Week_tabs {
  margin-top: 2rem;
}
.Winter_season_week_Test {
  background: #005484 0% 0% no-repeat padding-box;
  border: 1px solid #a7cddb;
  height: auto;
  width: 90%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1rem;
  border-radius: 10px;
}
.Winter_week_tab {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #a7cddb;
  border-radius: 4px;
  width: 99%;
  height: auto;
  margin-bottom: 1rem;
}
.Winter_week_Name_tab h4 {
  text-align: center;
  margin-top: 1rem;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 25%;
  border: 1px solid #fafafa;
  border-radius: 49px;
  background-color: #005484;
  color: #fff;
  font-size: 20px;
  font-weight: bold;
  padding: 0.5rem 0px 0.5rem 0px;
}
.Summer_Lottie {
  position: absolute;
  width: 8%;
  top: 18rem;
  margin-left: 1rem;
}
.Sunflower {
  width: 8%;
  position: absolute;
  right: 9rem;
  top: 33rem;
}
.Winter_Week_table_data {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 55%;
  text-align: justify;
  margin-bottom: 1.5rem;
}
.Winter_Week_table_data div {
  padding: 1rem 0rem 0.5rem 0rem;
  text-align: justify;
}
.Winter_Week_table_data div p {
  font-size: 16px;
  letter-spacing: 0px;
  color: #111111;
}
.Winter_Week_table_data div span {
  color: #707070;
  margin-left: 1rem;
  margin-right: 1rem;
}
.Winter_Week_table_data div #start {
  background: #a7cddb 0% 0% no-repeat padding-box;
  margin-left: 1rem;
  margin-right: 1rem;
  border: none;
  border-radius: 49px;
  padding: 0px 1rem 0px 1rem;
}
.Winter_Week_table_data div #redo {
  background: #989898 0% 0% no-repeat padding-box;
  margin-right: 1rem;
  margin-left: 1rem;
  border: none;
  border-radius: 49px;
  padding: 0px 1rem 0px 1rem;
}
.Winter_Week_table_data div #time {
  margin-right: 1rem;
  margin-left: 1rem;
  color: #111;
  background-color: transparent;
  border: none;
}
/* Spring Season Component Css */
.Spring_Season {
  background: transparent linear-gradient(180deg, #c3fc49 0%, #ffb4dc 100%) 0%
    0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  padding: 1rem 3rem 2rem 3rem;
  height: 33.5rem;
  margin-right: 0.5rem;
}
.Spring_Season_weeks button {
  border: none;
  background-color: transparent;
  margin-left: 2rem;
  margin-right: 6rem;
  color: #111;
  opacity: 0.5;
  font-size: 16px;
}
.Spring_Season_weeks #active {
  color: #111;
  opacity: 100%;
  font-weight: bold;
}
.Spring_Season_weeks button:active {
  color: #111;
  opacity: 100%;
  font-weight: bold;
}
.Spring_Season_weeks button:focus {
  color: #111;
  opacity: 100%;
  font-weight: bold;
}
.Spring_Season_weeks button:hover {
  color: #111;
  opacity: 100%;
  font-weight: bold;
}
.Spring_Week_tabs {
  margin-top: 2rem;
}
.Spring_season_week_Test {
  background: #95c136 0% 0% no-repeat padding-box;
  border: 1px solid #95c136;
  border-radius: 10px;
  height: auto;
  width: 90%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1rem;
}
.Spring_week_tab {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #95c136;
  border-radius: 4px;
  width: 99%;
  height: auto;
  margin-bottom: 1rem;
}
.Spring_week_Name_tab h4 {
  text-align: center;
  margin-top: 1rem;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 25%;
  border: 1px solid #fafafa;
  border-radius: 49px;
  background-color: #95c136;
  color: #111;
  font-size: 20px;
  font-weight: bold;
  padding: 0.5rem 0px 0.5rem 0px;
}
.Summer_Lottie img {
  position: absolute;
  top: -1.5rem;
  margin-left: 1rem;
}
.Sunflower {
  width: 8%;
  position: absolute;
  right: 9rem;
  top: 33rem;
}
.Spring_Week_table_data {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 55%;
  text-align: justify;
  margin-bottom: 1.5rem;
}
.Spring_Week_table_data div {
  padding: 1rem 0rem 0.5rem 0rem;
  text-align: justify;
}
.Spring_Week_table_data div p {
  font-size: 16px;
  letter-spacing: 0px;
  color: #111111;
}
.Spring_Week_table_data div span {
  color: #707070;
  margin-left: 1rem;
  margin-right: 1rem;
}
.Spring_Week_table_data div #start {
  background: #c9f557 0% 0% no-repeat padding-box;
  margin-left: 1rem;
  margin-right: 1rem;
  border: none;
  border-radius: 49px;
  padding: 0px 1rem 0px 1rem;
}
.Spring_Week_table_data div #redo {
  background: #989898 0% 0% no-repeat padding-box;
  margin-right: 1rem;
  margin-left: 1rem;
  border: none;
  border-radius: 49px;
  padding: 0px 1rem 0px 1rem;
}
.Spring_Week_table_data div #time {
  margin-right: 1rem;
  margin-left: 1rem;
  color: #111;
  background-color: transparent;
  border: none;
}
