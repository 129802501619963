.MyPracticeTest_wrapper {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #00000029;
  height: 4rem;
  padding-left: 7rem;
  padding-top: 1rem;
}
.MyPracticeTest_Dropdown select {
  border: 1px solid #e97838;
  border-radius: 8px;
  width: 9.5rem;
  color: #e97838;
  font-size: 18px;
  padding-left: 10px;
  padding-right: 30px;
}
.MyPracticeTest_Dropdown select:hover {
  background-color: #e97838;
  color: #fff;
}
.MyPracticeTest_Dropdown option {
  color: #fff;
  font-size: 16px;
  padding: 100px;
}
.MyPracticeTest_Dropdown #math {
  background-color: #e97838;
  border-radius: 13px;
  padding: 40px;
}
.MyPracticeTest_Dropdown #science {
  background-color: #6deea5;
  border-radius: 13px;
}
.curriculum_right_tab {
  float: right;
  margin-right: 1.8rem;
}
.curriculum_right_tab span {
  padding-right: 2rem;
  color: #918e96;
  font-size: 10px;
  display: none;
}
.curriculum_right_tab button {
  margin-right: 2rem;
  background: #c6e4ce 0% 0% no-repeat padding-box;
  border: 1px solid #eeeeee;
  border-radius: 4px;
  font-size: 12px;
  padding: 8px 15px;
}
.curriculum_right_tab button i {
  padding-left: 1rem;
  color: #666666;
  font-weight: 900;
}
.curriculum_right_tab img {
  width: 40px;
}
.curriculum_Wrapper {
  background-color: #fff;
  padding-left: 7rem;
  margin-top: 0.1rem;
  height: 90vh;
  box-sizing: border-box;
  /* margin-bottom: 1rem; */
}
.curriculum_unit_left {
  margin-top: 2rem;
  background-color: #fef9ef;
  height: 30rem;
  width: 96%;
}
.curriculum_unit_left .table {
  float: left;
  color: #111;
  font-size: 16px;
  background: #e0e0e0 0% 0% no-repeat padding-box;
  border-radius: 8px 8px 0px 0px;
  width: 100%;
  height: 40px;
  font-weight: normal;
}
.curriculum_unit_left .table > thead tr th {
  padding-left: 2rem;
  padding-bottom: auto;
  padding-top: 1rem;
  display: block;
}
.curriculum_unit_left1 {
  padding-top: 3rem;
}
.curriculum_unit_left p {
  text-align: center;
  align-items: center;
  background: #ffffff 0% 0% no-repeat padding-box;
  width: 60%;
  margin-left: 5rem;
  padding: 10px;
}
.curriculum_unit_left #active {
  background-color: #daccff;
  margin-top: 3rem;
}
.curriculum_unit_right {
  margin-top: 2rem;
  background-color: #fef9ef;
  height: 30rem;
  width: 100%;
}
.curriculum_unit_right .table > thead {
  color: #111;
  font-size: 16px;
  background: #e0e0e0 0% 0% no-repeat padding-box;
  border-radius: 8px 8px 0px 0px;
  width: 100%;
  height: 40px;
  font-weight: normal;
}
.curriculum_unit_right .table > thead tr th {
  padding-left: 2rem;
  padding-bottom: 1rem;
  padding-top: auto;
}
.curriculum_unit_right1 .table {
  margin-left: 1.5rem;
  width: 95%;
}
.curriculum_unit_right1 .table > tbody tr {
  background: #fff 0% 0% no-repeat padding-box;
  border-radius: 8px;
  border-bottom: 3px solid #fef9ef;
  color: #918e96;
}
.curriculum_unit_right1 .table > tbody tr td {
  padding-left: 2rem;
  padding-top: 1rem;
  /* border-right: 1px solid #918e96; */
}
.curriculum_unit_right1 .table > tbody tr td span {
  float: right;
  padding-right: 2rem;
  font-size: 16px;
  color: #8b60fd;
}
.curriculum_unit_right1 table {
  border-collapse: collapse;
  margin: 0 auto;
}
.curriculum_unit_right1 table td {
  padding: 1rem;
  border-right: 1px solid #918e96;
}
.curriculum_unit_right1 table tr:first-child td {
  border-top: 0;
}
.curriculum_unit_right1 table tr td:first-child {
  border-left: 0;
  border-bottom: 0;
}
.curriculum_unit_right1 table tr:last-child td {
  border-bottom: 0;
}
.curriculum_unit_right1 table tr td:last-child {
  border-right: 0;
}
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  background: #555555;
  width: 100%;
  height: 100vh;
  opacity: 0.5;
  z-index: 1;
}

.Curriculum_Card{
  background: #fff;
  margin: 0 auto;
  width: 55%;
  height: 440px;
  z-index: 2;
  border-radius: 4px;
  padding: 30px;
  display: block;
  margin-right: auto;
  margin-left: auto;
  position: absolute;
  top: 10rem;
  right: 21rem;
}
.Curriculum_Card img{
    position: absolute;
    right:2rem;
}