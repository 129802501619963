.Revision_tab_Wrapper {
  padding-left: 7rem;
  padding-top: 1rem;
}
.Revision_tab_buttons {
  float: right;
  margin-right: 2rem;
}
.Revision_tab_buttons button {
  margin-left: 2rem;
  background: #eeeeee 0% 0% no-repeat padding-box;
  border: 2px solid #eeeeee;
  font: normal normal normal 11px/15px Comic Sans MS;
  letter-spacing: 0px;
  color: #0d0d0d;
  padding: 8px 13px;
  border-radius: 8px;
}
.Revision_tab_buttons button.active {
  background-color: #0b92e8;
  color: #fff;
}
.revision_Tab_Tables {
  margin-top: 3rem;
  margin-right: 2rem;
}
.Revision_Lesson_List ul {
  background: #ffffff 0% 0% no-repeat padding-box;
  height: 27rem;
  padding-left: 0rem;
}
.Revision_Lesson_List ul li {
  border-bottom: none;
  padding: 0.5rem 0rem 0.5rem 1rem;
  list-style-type: none;
  color: #064974;
  font-size: 14px;
}
.Revision_Lesson_List ul li.active {
  background: #8b60fd 0% 0% no-repeat padding-box;
  border-radius: 4px 4px 0px 0px;
  color: #fff;
}
.Revision_Chapter_table {
  margin-left: 1rem;
  height: 27rem;
}
.Revision_Chapter_table .table {
  background: #ffffff 0% 0% no-repeat padding-box;
  width: 100%;
  border-radius: 12px 12px;
}
.Revision_Chapter_table .table > thead {
  background: #e0e0e0 0% 0% no-repeat padding-box;
  border: 1px solid #e0e0e0;
  text-align: center;
}
.Revision_Chapter_table .table > thead th {
  padding-bottom: 20px;
}
.Revision_Chapter_table .table > tbody tr td {
  border-bottom: none;
  border-right: 1px solid #e0e0e0;
  text-align: center;
  padding-top: 1rem;
  color: #918e96;
}
.Revision_Chapter_table .table > tbody tr td i{
  cursor: pointer;
}
.Revision_Chapter_table .table > tbody tr td span img {
  width: 2rem;
}
.Revision_Chapter_table .table > tbody tr td button {
  border: none;
  background-color: transparent;
  color: #8b60fd;
}
.Revision_Chapter_table table tr:first-child td {
  border-top: 0;
}
.Revision_Chapter_table table tr td:first-child {
  border-left: 0;
}
.Revision_Chapter_table table tr:last-child td {
  border-bottom: 0;
}
.Revision_Chapter_table table tr td:last-child {
  border-right: 0;
}
.Revision_pastpaper_Chapter_table .table {
  background: #ffffff 0% 0% no-repeat padding-box;
  width: 100%;
  /* padding-left: 10rem; */
}
.Revision_pastpaper_Chapter_table .table > thead {
  background: #e0e0e0 0% 0% no-repeat padding-box;
  border-radius: 8px 8px 0px 0px;
  border: 1px solid #e0e0e0;
  text-align: left;
  padding-left: 10rem;
}
.Revision_pastpaper_Chapter_table .table > thead th tr {
  padding-left: 2rem;
}
.Revision_pastpaper_Chapter_table .table > tbody tr td {
  /* border-bottom: none; */
  /* border-right: 1px solid #e0e0e0; */
  text-align: left;
  padding-top: 1rem;
  color: #064974;
}
.notes_pop_up{
  background-color: #fff;
  margin: 0 auto;
  width: 60%;
  height: 30rem;
  z-index: 2;
  padding: 0px;
  position: absolute;
  top: 25%;
  right: 18%;
  border: 1px solid #0b92e866;
  box-shadow: 0px 3px 6px #00000029;
  /* margin-top: 0.2rem; */
  box-sizing: border-box;
}
.notes_pop_up img{
  float: right;
  padding: 1rem;
  cursor: pointer;
}