@import url(//netdna.bootstrapcdn.com/font-awesome/4.0.3/css/font-awesome.css);
@import url(https://fonts.googleapis.com/css?family=Titillium+Web:300);
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap");
body {
  margin: 0;
  padding: 0;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  overflow: hidden;
}
.aside {
  position: absolute;
  top: 0;
  background-color: #ffffff;
  box-shadow: 0px 3px 6px #00000029;
  width: 100px;
  height: 100vh;
}
.navbar-brand {
  text-align: center;
  position: relative;
  top: 25px;
  right: 5px;
}
.Sidebar_Swap {
  margin-top: 15%;
}
.Sidebar_Swap ul li a img {
  position: absolute;
}
.Sidebar_Swap ul li {
  list-style-type: none;
  padding-top: 45px;
  font-size: 12px;
  padding-left: 5px;
}
.Sidebar_Swap ul li a img {
  width: 20px;
  height: 20px;
  text-align: center;
  cursor: pointer;
}
.nav-text {
  padding-left: 4rem;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  display: none;
  /* color:transparent; */
}
.Sidebar_Swap:hover + .nav-text {
  display: block;
  color: black;
  background-color: #30d0b6;
  padding: 10px;
  opacity: 0.8;
  width: 200px;
  margin-left: 0.5rem;
  font-size: 14px;
}
/* Swap Topbar CSS */
.navbar_swap {
  height: 70px;
  margin-left: 100px;
  font-family: "Poppins", sans-serif;
  width: 100%;
  background: #daccff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 4px #0000001a;
}
.Searchbar {
  display: inline;
  margin-top: 1rem;
  width: 80%;
}
.serch-icon {
  position: relative;
  left: 2rem;
  top: 0.5rem;
  padding-left: 1rem;
}
.Searchbar input {
  padding-left: 3rem;
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  height: 40px;
}
.Right-Side-align {
  align-items: center;
  float: right;
  padding-left: 25%;
}
/*Topbar Notification CSS */
.Notifications {
  padding-right: 30px;
}
.Notifications img {
  width: 15px;
  height: 20px;
}
/*Topbar Badges Css */
.Badges {
  padding-right: 30px;
}
.Badges img {
  width: 16px;
  height: 20px;
}
/*Topbar Profile_swap Css */
.profile-dropdown_swap {
  float: right;
  padding-left: 49px;
}
.profile-dropdown_swap li {
  display: inline-block;
}
.Profile_swap img {
  position: relative;
  width: 40px;
  height: 40px;
  top: 20px;
}
.Profile_swap,
.dropdown {
  margin-left: 10px;
}
.myprofile_top_data p {
  color: #fff !important;
  top: 15px;
  position: relative;
  font-size: 12px;
}
.myprofile_top_data span {
  position: relative;
  color: #ffffff;
  right: 0px;
  bottom: 1px;
  font-size: 8px;
}

.nav-link:hover,
.nav-link:focus {
  color: #fff;
  box-shadow: 5px white;
}
.dropdown span {
  font-size: 12px;
  font-family: "Poppins", sans-serif;
}
.dropdown:hover .top-user {
  display: block;
  background-color: #ffffff;
  right: -23px;
  /* left: 3px; */
  border: 1px solid #0b92e866;
  top: 75px;
  box-shadow: 0px 3px 6px #00000029;
  text-align: center;
  align-items: center;
  width: 250px;
  height: 210px;
  padding: 0px;
}
.profile-dropdown_swap .top-user a {
  text-decoration: none;
  padding: 0px;
  font-family: "Poppins", sans-serif;
}
.profile_dropdown_image {
  padding: 10px 10px 10px 10px;
}
.profile_dropdown_image img {
  width: 80px;
  position: relative;
  left: 70px;
}
.profile_dropdown_image i {
  float: right;
  position: relative;
  left: 100px;
  background-color: #fdf980;
  box-shadow: 3px 3px 3px #00000029;
  border: 2px solid #fafafa;
  height: 25px;
  width: 25px;
}
.profile_dropdown_image a {
  text-decoration: none;
  color: #111111;
}
.Profile_View_swap {
  border-bottom: 1px solid #00000029;
  padding-top: 10px;
  padding-bottom: 10px;
  letter-spacing: 0px;
  line-height: 1px;
}
.Profile_View_swap p {
  font-size: 14px;
  color: #111111;
  font-family: "Poppins", sans-serif;
}
.Profile_View_swap button {
  color: #8b60fd;
  border: none;
  background-color: #fff;
  font-size: 12px;
}
.SignOut_Page {
  margin-top: 10px;
  background-color: #ff6700;
  border-radius: 49px;
  width: 80px;
  margin-left: 35%;
  padding-top: 3px;
  padding-bottom: 5px;
}
.SignOut_Page button {
  border-color: #fafafa;
  background-color: #ff6700;
  padding: 2px 10px 2px 10px;
  border-radius: 49px;
  color: #fff;
  font-size: 12px;
  border: 1px solid #fafafa;
}
