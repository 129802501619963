.MyPracticeTest_wrapper {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #00000029;
  height: 4rem;
  padding-left: 7rem;
  padding-top: 1rem;
}
.MyPracticeTest_Dropdown select {
  border: 1px solid #e97838;
  border-radius: 8px;
  width: 9.5rem;
  color: #e97838;
  font-size: 18px;
  padding-left: 10px;
  padding-right: 30px;
}
.MyPracticeTest_Dropdown select:hover {
  background-color: #e97838;
  color: #fff;
}
.MyPracticeTest_Dropdown option {
  color: #fff;
  font-size: 16px;
  padding: 100px;
}
.MyPracticeTest_Dropdown #math {
  background-color: #e97838;
  border-radius: 13px;
  padding: 40px;
}
.MyPracticeTest_Dropdown #science {
  background-color: #6deea5;
  border-radius: 13px;
}
.MyPracticeTest_tabs {
  background: #ffffff 0% 0% no-repeat padding-box;
  /* height: auto; */
  height: 37rem;
  padding-left: 8.5rem;
  padding-top: 1rem;
  margin-top: 0.3rem;
  padding-bottom: 1rem;
}
.MyPracticeTest_tab_buttons button {
  background-color: transparent;
  border: none;
  margin-right: 5rem;
  font-size: 18px;
  letter-spacing: 0px;
  color: #111111;
  opacity: 0.5;
}
.MyPracticeTest_tab_buttons button.active {
  color: #8b60fd;
  opacity: 100%;
  border-bottom: 2px solid #8b60fd;
}
.MyPracticeTest_Chapter_Dropdown select {
  background: #fef9ef 0% 0% no-repeat padding-box;
  border-radius: 10px;
  border: none;
  width: 40%;
  font-size: 14px;
  margin-right: 2rem;
  padding: 0.5rem 1rem;
}
.MyPracticeTest_Chapter_Dropdown option {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 10px;
}
.MyPracticeTest_activity {
  background: #fef9ef 0% 0% no-repeat padding-box;
  width: 98%;
  margin-top: 1rem;
  padding: 1.5rem 1.5rem;
  height: 30rem;
}
.MyPracticeTest_table .table {
  text-align: left;
}
.MyPracticeTest_table .table > thead tr th {
  font-size: 20px;
  font-style: normal;
  font-weight: lighter;
  letter-spacing: 0px;
  color: #8b60fd;
  text-align: left;
}
.MyPracticeTest_table .table > tbody tr td {
  padding: 1rem 5rem 0rem 0.5rem;
  text-align: left;
  color: #111111;
  font-size: 16px;
  font-weight: normal;
}
.MyPracticeTest_table .table > tbody tr p {
  color: #ff6700;
}
.MyPracticeTest_table .table > tbody tr button {
  background: #8b60fdbe 0% 0% no-repeat padding-box;
  border-radius: 14px;
  border: none;
  width: 60%;
  height: 70%;
  font-size: 16px;
  /* font-size:28px;
  border:none;
  padding:16px 40px 16px 40px;
  border-radius:5px;
    box-shadow:0px 10px 0px 0px #1d7f8a, 
      0px 0px 10px 0px rgba(120, 120, 120, 0.4);
  color:white;
  background:#239dab;
  cursor:pointer;
  transition:all 0.16s;
  position:relative;
  top:0; */
}
/* .MyPracticeTest_table .table > tbody tr button:focus{
    outline:none;
  }
  .MyPracticeTest_table .table > tbody tr button:active{
    box-shadow: 0px 5px 0px 0px #1d7f8a;
    top:5px;
  } */
.MyPracticeTest_table .table > tbody tr h5 {
  font-size: 16px;
  color: #58c64f;
}
/* Activity Component Css */
.activity_Wrapper {
  padding: 2rem;
}
.activity_left_tab p {
  color: #064974;
  opacity: 100%;
  line-height: 0.8;
  font-weight: normal;
}
.Activity_chapter_title {
  border: 2px solid #e97838;
  padding: 0.3rem 0px 0px 0px;
  border-radius: 49px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
  height: 40px;
}
.Activity_Image img {
  position: absolute;
  left: 32.7rem;
  bottom: 39rem;
}
.Activity_chapter_title button {
  display: block;
  margin-left: auto;
  margin-right: auto;
  background: #e97838 0% 0% no-repeat padding-box;
  border-radius: 49px;
  color: #ffffff;
  font-size: 20px;
  border: none;
  padding: 0.3rem 2rem 0.3rem 2rem;
  box-shadow: 5px 0px 0px 0px #e97838, 0px 0px 10px 0px #e97838;
  color: white;
  cursor: pointer;
  transition: all 0.16s;
  position: relative;
  top: 0;
}
.activity_right_tab button i {
  color: #e97838;
  padding-right: 0.7rem;
  font-size: 13px;
}
.activity_right_tab button {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #0000000d;
  border: 1px solid #111111;
  border-radius: 6px;
  font-size: 12px;
  padding: 0.3rem 0.5rem;
}
.Activity_test_wrapper {
  margin-top: 0.5rem;
  border: 2px solid #989898;
  border-radius: 10px;
}
.Activity_close_icon img {
  position: absolute;
  right: 3rem;
}
.Activity_test_Data {
  border-right: 1px solid #989898;
  border-bottom: 1px solid #989898;
  padding: 0.5rem 2rem 0rem 2rem;
  height: 12rem;
  width: 100%;
}
.Active_Circle {
  padding-top: 2rem;
}
.Active_Circle span {
  background: #fdf980 0% 0% no-repeat padding-box;
  box-shadow: 3px 3px 3px #00000029;
  border: 2px solid #fafafa;
  border-radius: 10px;
  color: #064974;
  font-size: 23px;
  padding: 0px 0.7rem 0.4rem 0.7rem;
}
.Active_Image img {
  margin-left: 3rem;
  margin-top: 1rem;
  /* width: 100px; */
}
.Active_Image_space {
  margin-left: 3rem;
}
.Active_Image_space img {
  margin-right: 0.3rem;
  margin-top: 1rem;
}
.Active_Caluclations h6 {
  margin-top: 1rem;
}
.Active_Caluclations h6 span {
  margin-left: 3.8rem;
  font-size: 20px;
  letter-spacing: 0.9px;
  color: #064974;
}
.Active_Caluclations h6 input {
  width: 15%;
  height: 2rem;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #064974;
  border-radius: 4px;
  margin-left: 2rem;
}
.Active_marks {
  padding-top: 3rem;
}
.Active_marks span {
  color: #918e96;
  letter-spacing: 0.36px;
  margin-top: 2rem;
  font-size: 10px;
}
.Activity_Next #Activity_Next {
  position: absolute;
  right: 45.5rem;
  top: 39.6rem;
  background: #ff6700 0% 0% no-repeat padding-box;
  color: #fff;
  font-size: 16px;
  /* border: none; */
  border-radius: 49px;
  color: white;
  cursor: pointer;
  transition: all 0.16s;
  padding: 5px 18px 5px 18px;
  border: 1px solid #fff;
}
.Activity_Next #Activity_back {
  position: absolute;
  right: 48.1rem;
  top: 39.6rem;
  background: #918e96 0% 0% no-repeat padding-box;
  border-radius: 49px;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.16s;
  width: 6%;
  padding: 5px 18px 5px 18px;
  border: 1px solid #fff;
}
.Activity_Next #Activity_submit {
  position: absolute;
  right: 42.2rem;
  top: 39.6rem;
  background: #ff6700 0% 0% no-repeat padding-box;
  color: #fff;
  font-size: 16px;
  width: 6%;
  /* border: none; */
  border-radius: 49px;
  color: white;
  cursor: pointer;
  transition: all 0.16s;
  padding: 5px 20px 5px 18px;
  border: 1px solid #fff;
}
.Activity_bottom span {
  font-size: 10px;
  color: #064974;
}
.Activity_Page_number span {
  margin-left: 33rem;
  font-size: 10px;
  color: #918e96;
}
.Activity_Page_number span b {
  font-size: 10px;
  color: #064974;
}
/* Submit Form Css */
.Submit_Form {
  background-color: #f9f6ee;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  z-index: 2;
  padding: 0px;
  position: absolute;
  top: 0%;
  right: 0%;
  border-radius: 20px;
}
.Submit_Form_Border {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 10px solid #8b60fd;
  width: 30%;
  height: 35%;
  padding: 3rem 1rem 3rem 1rem;
  display: block;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  top: 33%;
  border-radius: 49px;
  text-align: center;
}
.Submit_Form_Border h4 {
  color: #ff6700;
  font-size: 20px;
}
.Submit_Form_buttons {
  margin-top: 2rem;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 35%;
}
.Submit_Form_buttons button {
  margin-right: 3rem;
  color: #fff;
  width: 50%;
}
#submit_back {
  background: #918e96 0% 0% no-repeat padding-box;
  border-radius: 49px;
  border: none;
  padding: 0px 0.8rem;
}
#submit_yes {
  background: #ff6700 0% 0% no-repeat padding-box;
  border-radius: 49px;
  border: none;
  padding: 0px 0.8rem;
}
.unicorn_dog {
  width: 5rem;
  display: block;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  top: 9.3rem;
}
.Submit_Form_Borders {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 10px solid #8b60fd;
  width: 30%;
  height: 35%;
  padding: 3rem 1rem 3rem 1rem;
  display: block;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  top: 20%;
  border-radius: 49px;
  text-align: center;
}
.Submit_Form_Borders h4 {
  color: #ff6700;
  font-size: 20px;
}
.Submit_Form_button p {
  color: #6deea5;
  font-size: 16px;
}
.Submit_Form_button span {
  color: #918e96;
  font-size: 16px;
}
.Submit_Form_button span b {
  color: #064974;
  font-size: 16px;
}
.Submit_close_icon {
  float: right;
  margin: 10px;
}
.Submit_View_solution_Form {
  display: block;
  position: relative;
  top: 9.5rem;
  left: 53rem;
}
.Submit_View_solution_Form button {
  border: none;
  background-color: transparent;
  color: #064974;
  font-weight: 600;
}
.activity_view_Solution {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 75%;
  margin-top: 3rem;
}
.view_Solution_left {
  margin-top: 1.2rem;
  width: 100%;
  text-align: justify;
}
.view_Solution_Circle span {
  background: #fdf980 0% 0% no-repeat padding-box;
  box-shadow: 3px 3px 3px #00000029;
  border: 2px solid #fafafa;
  border-radius: 10px;
  color: #064974;
  font-size: 18px;
  padding: 0px 0.7rem 0.4rem 0.7rem;
}
.view_Solution_header h5 span {
  margin-left: 4rem;
  font-size: 20px;
  letter-spacing: 0.9px;
  color: #064974;
}
.view_Solution_header h5 input {
  width: 10%;
  color: #064974;
  padding-left: 2px;
  margin-left: 1rem;
}
.View_Solution_Right {
  border: 2px solid #8b60fd;
  border-radius: 4px;
  margin-top: 1rem;
  height: 500px;
  background-color: #fff;
  padding: 10px;
  width: 80%;
}
.View_Solution_Right h5 {
  color: #8b60fd;
}
.View_Solution_Right p {
  color: #918e96;
}
.View_Solution_Right p li {
  color: #8b60fd;
}
