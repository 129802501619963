body {
  background-color: #fef9ef;
}
@media only screen and (min-width: 320px) and (max-width: 576px) {
  .Home_page_Wrapper {
    width: 100%;
    padding-left: 23px;
    flex-direction: column-reverse;
  }
}
.Content_Home_Wrapper {
  padding-left: 7rem;
  /* overflow-y:auto; */
  height: 90vh;
}
.Home_page_Wrapper {
  padding-top: 20px;
}
.Greetings h5 {
  color: #111111;
  font-size: 22px;
  margin-bottom: 1rem;
}
.loader-container {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  background-color: #fef9ef;
  z-index: 1;
}
.spinner {
  width: 64px;
  height: 64px;
  border: 8px solid;
  border-color: #8b60fd transparent #8b60fd transparent;
  border-radius: 50%;
  animation: spin-anim 1.2s linear infinite;
}
@keyframes spin-anim {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/* .Daily_Notifications_Image img {
  width: 100%;
} */
.Notices_home {
  position: absolute;
  top: 11rem;
  left: 10rem;
}
.Notices_home h4 {
  font-size: 30px;
  font-weight: bold;
}
.Notices_home p {
  font-size: 14px;
  color: #39311c;
  opacity: 100%;
}
.ProgressBar_ScoreBoard {
  margin-top: 1rem;
}
/* .Progressbar {
  margin-right: 1rem;
} */
.Progressbar h6 {
  color: #0a91e7;
  font-size: 18px;
}
.Progressbar button {
  color: #918e96;
  background-color: #fef9ef;
  border: #fef9ef;
  font-size: 14px;
}
.Progress_bars {
  background-color: #ffffff;
  border-radius: 12px;
  width: 100%;
  height: 300px;
  margin-top: 10px;
  padding: 1.1rem 7rem 5rem 7rem;
}
.Thermometer_Progressbar p {
  margin-top: 1rem;
  margin-left: 1.5rem;
  color: #064974;
  font-size: 1.2rem;
}
.Thermometer_Maths {
  margin-right: 5rem;
}
.Thermometer_Maths .thermometer .thermometer__bg-color {
  background-color: #e97838 !important;
  background: linear-gradient(#e97838, #e97838, #e97838);
}
.Thermometer_Maths .thermometer .thermometer__draw-b:after {
  background-color: #e97838 !important;
}
.Thermometer_Maths .thermometer .thermometer__draw-b:before {
  background-color: #e97838 !important;
}
.Thermometer_Science .thermometer .thermometer__draw-b:before {
  background-color: #6deea5 !important;
}
.Thermometer_Science .thermometer .thermometer__draw-b:after {
  background-color: #6deea5 !important;
}
.Thermometer_Science .thermometer .thermometer__bg-color {
  background-color: #6deea5 !important;
  background: linear-gradient(#6deea5, #6deea5, #6deea5);
  /* background-image: linear-gradient(
    #6deea5,
    #6deea5,
    #6deea5
  ); */
}
.ScoreBoard h6 {
  color: #0a91e7;
  font-size: 18px;
  margin-left: 4rem;
}
.ScoreBoard button {
  color: #918e96;
  background-color: #fef9ef;
  border: #fef9ef;
  font-size: 14px;
  margin-right: 4rem;
}
.Score_Boards_Subject {
  margin-left: 4rem;
  margin-top: 10px;
}
.Score_Boards_Subject .card {
  background-color: #fff;
  border: 2px solid #e97838;
  height: 9rem;
  width: 90%;
}
.Score_Boards_Subject .card-text h6 {
  color: #e97838;
  padding-top: 2rem;
}
.Score_Boards_Subject .card-text p {
  color: #111111;
  margin-top: 0rem;
  margin-left: 4rem;
  border: 2px solid #e97838;
  border-radius: 50%;
  padding: 0.5rem;
}
.Score_Boards_Subject .card-text p span {
  background-color: #e97838;
  display: block;
  padding: 1.5rem;
  border-radius: 50%;
}
.Maths_Score_Board img {
  height: 9rem;
}
/* .Maths_Score_Board h6 {
  position: relative;
  top: -95px;
  left: 50px;
  color: #e97838;
  font-weight: bold;
  font-size: 20px;
} */
.Maths_Average img {
  position: absolute;
  top: 25rem;
  right: 35rem;
  width: 6rem;
}
.Maths_Score_Board p {
  position: absolute;
  top: 27.2rem;
  right: 35.5rem;
  font-size: 10px;
  font-weight: 900;
  color: #111111;
  padding: 20px;
}
.Science_Score_Board img {
  height: 9rem;
  position: absolute;
  top: 35rem;
}
.Science_Score_Board h6 {
  position: absolute;
  top: 39rem;
  right: 44rem;
  color: #6deea5;
  font-weight: bold;
  font-size: 20px;
}
.Science_Score_Board p {
  position: absolute;
  top: 37.3rem;
  right: 35.5rem;
  font-size: 10px;
  font-weight: 900;
  color: #111111;
  padding: 20px;
}
.Science_Average img {
  position: absolute;
  top: 35rem;
  right: 35rem;
  width: 6rem;
}
.Right_Side_calender {
  background-color: #ffffff;
  width: 399px;
  height: 600px;
  margin-left: 30px;
  border-radius: 12px;
}
@media only screen and (min-width: 320px) and (max-width: 576px) {
  .Right_Side_calender {
    width: 100%;
    padding-left: 23px;
    flex-direction: column-reverse;
  }
}
.Background_Image {
  text-align: center;
  padding-top: 15px;
  height: 100px;
}
.Left_Side_iamges #ConicalFlask {
  position: relative;
  top: -77px;
  left: 40px;
}
/* .Left_Side_iamges #Multi {
  position: relative;
  top: -38px;
  right: 60px;
}
.Left_Side_iamges #React {
  position: relative;
  top: -55px;
  left: 28px;
}
.Left_Side_iamges #Formula {
  position: relative;
  top: -85px;
  right: 95px;
} */
.Right_side_text {
  text-align: center;
  position: relative;
  left: 180px;
  top: -165px;
  color: #020723;
  font-size: 18px;
  font-weight: 600;
}
.SnowMen_Data {
  width: 150px;
  position: absolute;
  right: 9.3rem;
  top: 15rem;
}
.SnowMen_Data i {
  position: relative;
  left: 50px;
  color: #ff6700;
  bottom: -20px;
  font-size: 20px;
  background-color: #fdf980;
  box-shadow: 3px 3px 3px #00000029;
  border-radius: 12px;
  padding: 0px 5px 2px 5px;
  border: 2px solid #fafafa;
}
#Profile_Name {
  text-align: center;
  position: absolute;
  top: 23rem;
  right: 12rem;
  color: #8b60fd;
  font-size: 15px;
}
.calender img {
  width: 100%;
  height: 17rem;
  margin-top: 2rem;
  /* padding-top: 1rem; */
  /* text-align: center;
  position: relative;
  top: -170px;
  padding: 20px 30px 20px 30px; */

  /* width: 350px; */
}
/* .react-calendar {
  border: 2px solid #e6ffbe;
  background-color: #e6ffbe;
}
.react-calendar__navigation {
  background-color: #ffb252;
} */
/* .react-calendar__viewContainer{
  border: 1px solid red!important;
} */
/* .react-calendar__navigation__arrow {
  color: #fff;
  font-size: 18px;
  font-weight: 800;
}
.react-calendar__navigation__label {
  color: #ffffff;
  font-size: 14px;
  font-weight: 800;
}
.react-calendar__month-view__weekdays {
  background-color: #464b94;
  color: #fff;
}
.react-calendar__month-view__weekdays__weekday abbr[title] {
  text-decoration: none !important;
} */
/* .react-calendar__month-view__days */
/* .react-calendar__month-view__days {
  background-color: #64c08a !important;
}
.react-calendar__tile {
  color: #fff;
}
.react-calendar__tile--active {
  background-color: #8b60fd !important;
} */
/* .react-calendar__month-view__days__day--weekend {
  background-color: #FFB252!important;
} */
/* ProgressbarViewAll Page CSS */
.ProgressbarViewAll_wrapper {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #00000029;
  height: 4rem;
  padding-left: 7rem;
  padding-top: 1rem;
}
.Progressbar_Dropdown select {
  border: 1px solid #e97838;
  border-radius: 8px;
  width: 9.5rem;
  color: #e97838;
  font-size: 18px;
  padding-left: 10px;
  padding-right: 30px;
}
.Progressbar_Dropdown select:hover {
  background-color: #e97838;
  color: #fff;
}
.Progressbar_Dropdown option {
  color: #fff;
  font-size: 16px;
  padding: 100px;
}
#math {
  background-color: #e97838;
  border-radius: 13px;
  padding: 40px;
}
#science {
  background-color: #6deea5;
  border-radius: 13px;
}
.Progressbar_Design {
  margin-top: 2rem;
}
.ProgressBar_C1 span {
  position: relative;
  right: 2.4rem;
  top: 0.9rem;
  color: #fff;
}
.ProgressBar_C1 .progress {
  margin-top: 20px;
  background-color: #63433b;
  border: 2px solid #63433b;
  height: 1.5rem;
  border-radius: 12px;
}
/* .ProgressBar_C1 .progress-bar-striped {
  background-image: linear-gradient(to right#82C940, #bdff51, #82c940);
} */
.ProgressBar_C1 .progress-bar {
  background-color: #82c940;
}
.ProgressBar_C2 span {
  position: relative;
  right: 2.4rem;
  top: 0.9rem;
  color: #fff;
}

.ProgressBar_C2 .progress {
  margin-top: 20px;
  background-color: #63433b;
  border: 2px solid #63433b;
  height: 1.5rem;
  border-radius: 12px;
}
/* .ProgressBar_C2 .progress-bar-striped {
  background-image: linear-gradient(to right#82C940, #bdff51, #82c940);
} */
.ProgressBar_C2 .progress-bar {
  background-color: #82c940;
}
.ProgressBar_C3 span {
  position: relative;
  right: 2.4rem;
  top: 0.9rem;
  color: #fff;
}
.ProgressBar_C3 .progress {
  margin-top: 20px;
  background-color: #63433b;
  border: 2px solid #63433b;
  height: 1.5rem;
  border-radius: 12px;
}
/* .ProgressBar_C3 .progress-bar-striped {
  background-image: linear-gradient(to right#82C940, #bdff51, #82c940);
} */
.ProgressBar_C3 .progress-bar {
  background-color: #82c940;
}
.ProgressBar_Card_View {
  margin-top: 10px;
}
.ProgressBar_Card_View img {
  border: 5px solid #33221f;
  border-radius: 15px;
  width: 3.5rem;
  background-color: rgb(22, 183, 49);
}
.ProgressBar_Card_View img.Inactive {
  border: 5px solid #33221f;
  border-radius: 15px;
  width: 3.5rem;
  background-color: grey;
}
/* .ProgressBar_Card_View img.active {
  border: 5px solid #33221f;
  border-radius: 15px;
  width: 5rem;
  background-color: rgb(22, 183, 49);
} */
/* .ProgressBar_Card_View  span{
  margin-top: 20px;
}
.ProgressBar_Card_View .active{
  border: 5px solid #33221f;
  border-radius: 15px;
  width: 10rem;
  color:green;
  background-color: green;
} */
.ProgressBar_Card_View p {
  margin-left: 10px;
  border: 5px solid #33221f;
  border-radius: 5px;
  width: 85%;
  padding: 10px;
  background-color: #fff;
  color: #000000;
  font-size: 16px;
}
#iconScroll_right {
  /* background: transparent linear-gradient(180deg, #F7C700 0%, #FF6700 100%) 0% 0% no-repeat padding-box; */
  background: #fdf980 0% 0% no-repeat padding-box;
  box-shadow: 3px 3px 3px #00000029;
  border: 2px solid #fafafa;
  padding: 0px 4px 2px 6px;
  font-size: 25px;
  float: right;
  margin-right: 3rem;
  color: #ff6700;
}
#iconScroll {
  /* background: transparent linear-gradient(180deg, #F7C700 0%, #FF6700 100%) 0% 0% no-repeat padding-box; */
  background: #fdf980 0% 0% no-repeat padding-box;
  box-shadow: 3px 3px 3px #00000029;
  border: 2px solid #fafafa;
  padding: 0px 4px 2px 6px;
  font-size: 25px;
  border-radius: 12px;
  /* float: right; */
  margin-right: 3rem;
  color: #ff6700;
}
/* Score Board Css */
.ScoreViewAll_Wrapper {
  padding-left: 7rem;
  padding-top: 1rem;
  margin-top: 3rem;
  margin-bottom: 5rem;
}
.view-all-left-dta {
  background: #ffffff;
  margin: 20px 30px 20px 5px;
  height: 500px;
}
.view-all-left-dta img {
  width: 250px;
  position: relative;
  left: 35%;
  top: 20%;
}
.view-all-left-dta h6 {
  text-align: center;
  padding-top: 110px;
  color: #111111;
  font-size: 16px;
}
.view-all-left-dta p {
  text-align: center;
  padding-top: 4px;
  color: #777777;
  font-size: 12px;
  position: relative;
  padding-left: 180px;
  padding-right: 150px;
}
.view-all-right-dta {
  background: #ffffff;
  height: 500px;
  margin: 20px 20px 20px 5px;
  padding-top: 5rem;
  padding-left: 80px;
  font-size: 12px;
}
.view-all-right-dta select {
  display: block;
  width: 80%;
  height: 40px;
  margin-bottom: 30px;
  background-color: #fef9ef;
  border: 1px solid #ff6700;
  border-radius: 8px;
  padding-left: 10px;
  padding-right: 10px;
  color: #08134a;
}
.view-all-right-dta select option {
  background-color: #fef9ef;
  border: 1px solid #ff6700;
}
.view-all-right-button button {
  background: #ff6700 0% 0% no-repeat padding-box;
  border-radius: 49px;
  /* width: 35%; */
  padding: 1px 10px 3px 10px;
  margin-top: 8rem;
  margin-right: 4rem;
  float: right;
  color: #ffffff;
  font-size: 16px;
}
.view-all-left-dta .table > thead th {
  background-color: #00000029;
  box-shadow: 0px 3px 0px #f2f7ff80;
  padding-left: 30px;
  height: 60px;
  padding-bottom: 20px;
  font-size: 16px;
}

.view-all-left-dta tbody,
tfoot,
tr,
td {
  height: 60px;
}
.view-all-left-dta .table > tbody tr td {
  font-size: 15px;
  border-bottom: none;
}
.view-all-left-dta .table > tbody tr td span {
  padding-left: 20px;
  color: #918e96;
}
.view_paragraph {
  padding: 30px 0px 20px 30px;
  font-size: 16px;
  color: #8b60fd;
  font-weight: bold;
}
#view_p2 {
  padding-right: 40px;
  float: right;
}
/* Edit Avathar CSS */
.EditAvathar_Wrapper {
  background-image: linear-gradient(to bottom, #89afe0, #daccff, #d3caea);
  height: calc(100vh - 60px);
}
.Close_Avathar a {
  text-decoration: none;
  color: #111;
}
.Close_Avathar i {
  float: right;
  font-size: 20px;
  margin-right: 2rem;
  margin-top: 1.5rem;
  background-color: #fdf980;
  box-shadow: 3px 3px 3px #00000029;
  border: 2px solid #fafafa;
  padding: 0px 5px 3px 6px;
  border-radius: 8px;
}
.EditAvathar_Button {
  padding-top: 5rem;
  text-align: center;
}
.EditAvathar_Button #Sqillup_Avatar {
  /* background: transparent linear-gradient(177deg, #fbdb51 0%, #f8b41d 100%) 0%
    0% no-repeat padding-box;
  border: 2px solid #707070;
  box-shadow: 0px 2px 8px 2px #707070;
  border-radius: 56px;
  width: 12rem;
  height: 3rem;
  margin-right: 1rem;
  font-weight: bold; */
  /* 
 
  /* padding:16px 40px 16px 40px; */
  border: 2px solid #707070;
  width: 12rem;
  height: 3rem;
  border-radius: 56px;
  box-shadow: 0px 5px 0px 0px #f8b41d, 0px 0px 10px 0px #707070;
  font-weight: bold;
  background: transparent linear-gradient(177deg, #fbdb51 0%, #f8b41d 100%) 0%
    0% no-repeat padding-box;
  cursor: pointer;
  transition: all 0.16s;
  position: relative;
  top: 0;
  margin-right: 1rem;
}
.EditAvathar_Button #Sqillup_Avatar:focus {
  outline: none;
}
.EditAvathar_Button #Sqillup_Avatar:active {
  box-shadow: 0px 5px 0px 0px #f8b41d;
  top: 5px;
}
.EditAvathar_Button #Student_Avatar {
  /* background: transparent linear-gradient(177deg, #b4ad9f 0%, #918e96 100%) 0%
    0% no-repeat padding-box;
  box-shadow: 0px 2px 8px 2px #707070;
  border: 2px solid #707070;
  border-radius: 56px;
  width: 12rem;
  height: 3rem;
  font-weight: bold; */
  border: 2px solid #707070;
  width: 12rem;
  height: 3rem;
  border-radius: 56px;
  box-shadow: 0px 5px 0px 0px #918e96, 0px 0px 10px 0px #707070;
  font-weight: bold;
  background: transparent linear-gradient(177deg, #b4ad9f 0%, #918e96 100%) 0%
    0% no-repeat padding-box;
  cursor: pointer;
  transition: all 0.16s;
  position: relative;
  top: 0;
  margin-right: 1rem;
}
.EditAvathar_Button #Student_Avatar:focus {
  outline: none;
}
.EditAvathar_Button #Student_Avatar:active {
  box-shadow: 0px 5px 0px 0px #918e96;
  top: 5px;
}
.Avatar_Wrapper {
  margin-top: 4rem;
  margin-left: 8rem;
  margin-right: 5rem;
}
.Sqillupavatar_img {
  cursor: pointer;
  flex-wrap: wrap;
}
.Sqillupavatar_img img {
  width: 150px;
  height: 150px;
  margin-bottom: 30px;
  background: #fbfbfb 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 3px solid #c80040;
  border-radius: 30px;
  padding: 30px 15px;
}
/* .Sqillupavatar_img i{
  background: #fbfbfb 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 3px solid #c80040;
  border-radius: 30px;
  padding: 30px 15px;
  font-size: 3rem;
  margin-bottom: 80px;
} */
.Avatar_Buttons {
  background: transparent linear-gradient(180deg, #8b60fd 0%, #89afe0 100%) 0%
    0% no-repeat padding-box;
  height: 8.8rem;
  /* margin-top: 12rem; */
  position: absolute;
  width: 100%;
  bottom: 0.1rem;
  margin-top: auto;
  /* margin-bottom: 0rem; */
  text-align: center;
}
.Avatar_Buttons button {
  margin-top: 3rem;
  background: #e82968 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 48px;
  border: 2px solid #fff;
  color: #fbfbfb;
  text-transform: uppercase;
  width: 8rem;
  height: 1.8rem;
  font-size: 12px;
}
/* .Upload_file {
  background-color: #fff;
  margin: 0 auto;
  width: 49%;
  height: 38%;
  z-index: 2;
  padding: 0px;
  position: absolute;
  top: 25%;
  right: 18%;
  border: 1px solid #707070;
  box-shadow: 1px 0px 1px 0px #707070;
} */
.open_file_container {
  background-color: #fff;
  margin: 0 auto;
  width: 40%;
  height: 40%;
  z-index: 2;
  padding: 0px;
  position: absolute;
  top: 25%;
  right: 29%;
  border: 10px solid #8b60fd;
  border-radius: 20px;
}
.open_file_container .Upload_file_top {
  padding: 10px;
  margin-bottom: 20px;
  margin-top: 2rem;
  margin-left: 1.2rem;
}
.Upload_file_top h6 {
  font-size: 18px;
  color: #39311c;
}
.Upload_file_top h5 {
  float: right;
  padding-right: 2rem;
  color: #8b60fd;
  font-size: 20px;
}
/* .Upload_file_top i {
  font: normal normal normal 18px/27px Segoe UI;
  color: #fafafa;
  cursor: pointer;
}
.upload_file_input p {
  font: normal normal normal 12px/27px Segoe UI;
  color: #707070;
  margin-bottom: 0px;
  padding: 10px 10px 10px 30px;
}
.upload_file_input i {
  position: relative;
  float: right;
  top: -38px;
  padding-right: 10%;
} */
.upload_file_input input {
  background-color: #fff;
  border: 1px solid #ff6700;
  margin: 10px 40px 5px 30px;
  width: 88%;
  font-size: 12px;
  color: #8b60fd;
}
/* .upload_file_input span {
  font: normal normal normal 12px/27px Segoe UI;
  color: #707070;
  padding: 0px 10px 10px 30px;
} */
.Upload_file_buttons {
  padding-top: 40px;
  padding-right: 50px;
}
.Upload_file_buttons #cancel {
  color: #ffffff;
  border: none;
  background: #918e96 0% 0% no-repeat padding-box;
  border-radius: 12px;
  margin: 10px;
  width: 5rem;
  height: 1.8rem;
}
.Upload_file_buttons #save {
  color: #ffffff;
  border: none;
  background: #ff6700 0% 0% no-repeat padding-box;
  border-radius: 12px;
  margin: 10px;
  width: 5rem;
  height: 1.8rem;
}
/* My Profile Page CSS */
.MyProfile_Wrapper {
  padding-left: 7rem;
  height: 90vh;
  background-image: url("/public/PrimeStudentImages/Myprofile_Bg_Icon.svg");
}
.MyProfile_Form_data {
  margin-top: 4.3rem;
}
.MyProfile_Form_data img {
  margin-left: 0px;
  width: 12rem;
  border-radius: 50%;
  border: 3px solid #8b60fd;
}
.MyProfile_Form {
  background-color: #ffffff;
  width: 63rem;
  height: auto;
  padding: 10px 40px 20px 40px;
  border: 2px solid #8b60fd;
}
.MyProfile_Form h6 {
  color: #111111;
  font-size: 18px;
}
.MyProfile_Form label {
  border: 1px solid #8b60fd53;
  border-radius: 5px;
  float: left;
  padding-left: 20px;
  padding-top: 8px;
  height: 40px;
  font-size: 14px;
  width: 98%;
  margin-top: 20px;
  color: #111111;
}
.MyProfile_Form input {
  border: none;
  float: right;
  padding-right: 50px;
  /* height: 20px;
  border: 1px solid #8b60fd53; */
  /* height: 40px; */
  /* padding-top: 20px; */
}
.AbcsBoard {
  width: 10rem;
  position: relative;
  left: -7rem;
  top: -0.8rem;
}
.GiftBox {
  width: 10rem;
  float: right;
  position: absolute;
  bottom: 20rem;
  right: 10rem;
}

/* Notification Component CSS */
.notifi-content-wrapper {
  margin-left: 100px;
  padding: 25px;
  /* background: #F2F7FF; */
  overflow-y: auto;
  height: calc(100vh - 60px);
}
.notfy-circle {
  width: 40px;
  background-color: #6deea5;
  height: 40px;
  padding: 5px;
  border-radius: 50%;
  text-align: center;
  color: #fff;
  font-size: 20px;
  font-weight: 600;
}
.yellow-cicle {
  background-color: #e97838;
}
.notification {
  width: 100%;
  height: auto;
  background-color: #fff;
  box-shadow: 0px 0px 4px #0000000d;
  border-radius: 10px;
}
.notification__item {
  width: 100%;
  height: auto;
  padding: 12px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
}
.notification__column {
  display: flex;
  align-items: center;
}
.notification__column {
  display: flex;
  align-items: center;
}
.notification__img {
  width: 90px;
  height: auto;
}
.notification__info {
  margin-left: 14px;
  margin-top: 20px;
  width: auto;
}
.notification__company {
  color: #111111;
  font-size: 14px;
  display: block;
  font-weight: 700;
}
.notification__title {
  color: #333;
  font-size: 12px;
  font-weight: 700;
  display: block;
  margin-bottom: 15px;
  width: 270px;
}
.tag:hover,
.tag--active {
  background-color: #5da4a4;
  color: #fff;
}
.tag,
.close-tag {
  display: inline-block;
  width: auto;
  height: auto;
  font-weight: 600;
  font-size: 13px;
  color: #000000;
  cursor: pointer;
  position: relative;
}
.bg-grey {
  background-color: #f4f5ff;
}
.notification-settigs {
  background-color: #fff;
}
.notify-card {
  padding: 12px;
}
.notification-settigs h5 {
  text-align: left;
  font-size: 16px;
  letter-spacing: 0.72px;
  color: #000000;
  opacity: 1;
  background-color: #00000029;
  padding: 10px;
}

/* Switch 1 Specific Styles Start */

.box_1 {
  margin: 5px 0;
}

input[type="checkbox"].switch_1 {
  font-size: 30px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 38px;
  height: 20px;
  background: #ddd;
  border-radius: 3em;
  position: relative;
  cursor: pointer;
  outline: none;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

input[type="checkbox"].switch_1:checked {
  background: #4bbb46;
}

input[type="checkbox"].switch_1:after {
  position: absolute;
  content: "";
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #fff;
  -webkit-box-shadow: 0 0 0.25em rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 0.25em rgba(0, 0, 0, 0.3);
  -webkit-transform: scale(0.7);
  transform: scale(0.7);
  left: 0;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

input[type="checkbox"].switch_1:checked:after {
  left: calc(100% - 0.7em);
}
.switch_box label {
  padding-left: 10px;
  position: relative;
  font-size: 13px;
  position: relative;
  top: -8px;
}

/* Switch 1 Specific Style End */
/* Badges/Rewards CSS */
.Badges_Wrapper {
  background-color: #0c1341;
  width: 100%;
  height: 40.2rem;
  padding-left: 7rem;
}
.Badges_buttons {
  padding-top: 2rem;
  /* border-bottom: 3px solid #40404d; */
  width: 25%;
}
.Badges_buttons button {
  border: none;
  background-color: transparent;
  color: #fff;
  font-size: 18px;
}
.Badges_buttons button img {
  padding-right: 1rem;
}
.Badges_buttons button:last-child {
  margin-left: 3rem;
}
.Badges_buttons button.activate {
  border: none;
  color: #ff6700;
  border-bottom: 3px solid #ff6700;
  width: 33%;
}
.badges_top {
  margin-top: 2rem;
}
.reward_left_side h5 {
  color: #fff;
  font-size: 18px;
  font-weight: bold;
}
.reward_left_side p {
  color: #fbfbfb;
  font-size: 13px;
  font-weight: normal;
  float: right;
  margin-right: 12rem;
}
.rewards_images {
  margin-top: 1rem;
  margin-bottom: 5rem;
}
.rewards_images i {
  color: #ffac00;
  font-size: 22px;
  position: relative;
  left: 5.9rem;
  top: -2rem;
}
.rewards_images img {
  background: #707070 0% 0% no-repeat padding-box;
  border: 2px solid #ff9d00;
  border-radius: 11px;
  height: 6.5rem;
  width: 6rem;
  padding: 1rem 1.5rem;
}
.reward_left_bottom_side h5 {
  color: #fff;
  font-size: 18px;
  font-weight: bold;
}
.reward_left_bottom_side p {
  color: #fbfbfb;
  font-size: 13px;
  font-weight: normal;
  float: right;
  margin-right: 12rem;
}
.rewards_images_left_bottom {
  margin-top: 2rem;
}
.rewards_images_left_bottom img {
  border: 2px solid #ff9d00;
  border-radius: 11px;
  height: 6.5rem;
  width: 6rem;
  padding: 1rem 1.5rem;
  margin-left: 1rem;
}
.right-image img {
  width: 500;
  height: 400px;
  position: relative;
  right: 25%;
  top: -100px;
}
.right-badges p {
  position: relative;
  left: 12%;
  bottom: 180px;
  color: #ffffff;
  font-size: 15px;
  font-weight: bold;
}
.badges-paragraph3 hr {
  position: relative;
  right: 20%;
  border-top: 1px solid #eaf0eb;
  bottom: 180px;
  width: 100%;
}
.badges-paragraph1 p {
  /* position: relative;
  right: 10px; */
  color: #ffffff;
  text-align: left;
  padding-left: 3rem;
}
.badges-paragraph2 p {
  position: relative;
  left: 13px;
  text-align: left;
  /* padding-right: 3rem; */
  color: #7fc368;
  opacity: 100%;
}
.badges-paragraph3 p {
  position: relative;
  left: 4rem;
  color: #ff9d00;
}
/* Notification POP UP */
.Notification_POP_up {
  background-color: #fef9ef;
  margin: 0 auto;
  width: 20%;
  height: auto;
  z-index: 2;
  padding: 0px;
  position: absolute;
  top: 100%;
  right: 23%;
  border: 1px solid #0b92e866;
  margin-top: 0.2rem;
  box-sizing: border-box;
  box-shadow: 0px 3px 6px #00000029;
  /* border-radius: 20px; */
}
.Notification_POP_up img {
  float: right;
  /* padding: 1rem; */
}
.notification_design {
  padding-top: 1rem;
  padding-left: 1rem;
  padding-right: 0rem;
  padding-bottom: 0rem;
  display: flex;
}
.notification_left img {
  width: 3rem;
  background: #46b382 0% 0% no-repeat padding-box;
  padding: 10px;
  border-radius: 50%;
  margin-right: 0.5rem;
}
.Notification_POP_up .active {
  background-color: #0b92e833;
  padding-bottom: 0.8rem;
}
.notification_right {
  /* padding-bottom: 0.3rem; */
  padding-top: 5px;
}
.notification_right p {
  font-size: 11px;
  color: #111;
  font-weight: bold;
  padding-top: 5px;
}
.notification_right img {
  position: relative;
  top: -1.5rem;
  cursor: pointer;
}
.Notification_POP_up button {
  background-color: transparent;
  border: none;
  float: right;
  padding: 0.5rem;
  color: #8b60fd;
  font-size: 12px;
  opacity: 100%;
  font-weight: bold;
}
