/* body {
  overflow-y: scroll;
} */
.Courses_Wrapper {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #00000029;
  height: 4rem;
  padding-left: 7rem;
  padding-top: 1rem;
}
.Courses_Dropdown select {
  border: 1px solid #e97838;
  border-radius: 8px;
  width: 9.5rem;
  color: #e97838;
  font-size: 18px;
  padding-left: 10px;
  padding-right: 30px;
}
.Courses_Dropdown select:hover {
  background-color: #e97838;
  color: #fff;
}
.Courses_Dropdown option {
  color: #fff;
  font-size: 16px;
  padding: 100px;
}
#math {
  background-color: #e97838;
  border-radius: 13px;
  padding: 40px;
}
#science {
  background-color: #6deea5;
  border-radius: 13px;
}
.Courses_Cards {
  margin-top: 3rem;
  margin-left: 8rem;
}
.Courses_Cards img {
  width: 16rem;
  margin-bottom: 3.5rem;
  cursor: pointer;
}
.Courses_Cards h5 {
  position: relative;
  right: 13rem;
  top: 3rem;
  /* font: normal normal normal 20px/25px Asap; */
  font-size: 20px;
  background-color: #fff;
  height: 8rem;
  border-radius: 8px;
  padding: 10px;
  cursor: pointer;
}
.Courses_Cards h5 b {
  position: relative;
  top: 2rem;
  /* font: normal normal bold 20px/25px Asap; */
}
/* Lessons CSS */
.Lesson_Wrapper {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #00000029;
  height: 4rem;
  padding-left: 7rem;
  padding-top: 1rem;
}
.Lesson_top_left h5 {
  color: #918e96;
  font-size: 18px;
}
.Lesson_top_left h5 b {
  font-size: 20px;
}
.Lesson_top_left h5 span {
  color: #8b60fd;
  font-size: 18px;
  font-weight: normal;
}
.Lesson_top_right {
  float: right;
  padding-left: 70rem;
}
.Lesson_top_right button {
  background: #fdf980 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #0000000d;
  border: 1px solid #064974;
  border-radius: 5px;
  font-size: 15px;
  color: #111111;
  padding: 5px 15px 5px 15px;
  float: right;
}
.Lesson_Types {
  margin-top: 2rem;
  margin-left: 3rem;
}
.Lesson_Types button {
  border: none;
  background-color: #fef9ef;
  margin-right: 3.5rem;
  font-size: 16px;
  color: #918e96;
  display: inline;
}
.Lesson_Types button span img {
  width: 12px;
}
.Lesson_Types button.active {
  color: #8b60fd;
  border-bottom: 3px solid #8b60fd;
}
.Lessons_tabs_data {
  margin-top: 2rem;
  margin-left: 6rem;
}
/* .Lessons_tab_cards{

} */
.Lessons_tab_cards .card {
  background: #b2e9ff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  width: 75%;
  height: 13rem;
  margin-bottom: 3.5rem;
  cursor: pointer;
  margin-right: 5rem;
}

.Lessons_tab_cards .card-title p {
  text-align: center;
  color: #000000;
  font-size: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.Lessons_tab_cards .card-title img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  background-color: #fff;
  margin-top: 2rem;
  padding: 15px 30px;
  border-radius: 5px;
}
.Lessons_tab_cards .card-footer1 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.Lessons_tab_cards .card-footer1 img {
  width: 30px;
}
.Lessons_tab_cards .card-footer1 time {
  font-size: 12px;
}
.Lessons_tab_cards .card-footer1 i {
  float: right;
  font-size: 20px;
  color: #111111;
}
/* LessonView CSS  */
.Lesson_View_Wrapper {
  height: 90vh;
  padding-left: 7rem;
  padding-top: 1.5rem;
}
.Lesson_View_Wrapper p {
  font-weight: normal;
  font-size: 20px;
}
.Lesson_View_right button {
  background: #fdf980 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #0000000d;
  border: 1px solid #064974;
  border-radius: 5px;
  font-size: 12px;
  color: #111111;
  padding: 5px 15px 5px 15px;
  float: right;
  margin-right: 3rem;
}
.Lesson_View_Video {
  margin-top: 0.8rem;
}
.Lesson_Video_List {
  background-color: #fff;
  width: 90%;
  height: 30rem;
  padding: 1rem 0.8rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  /* overflow-y: scroll; */
}
.Lesson_Video_List h5 {
  color: #344356;
  font-weight: bold;
  font-size: 16px;
}
.Lesson_Video_column {
  /* background-color: #8b60fd; */
  box-shadow: 10px 14px 20px #fef9ef;
  border-radius: 10px;
  width: 90%;
  padding: 10px;
  margin-bottom: 10px;
  /* color: #ffffff; */
}
.Lesson_Tab_data {
  cursor: pointer;
  flex-wrap: wrap;
}
.Lesson_view_Video_List_Left img {
  background-color: #fff;
  width: 60px;
  margin: 10px;
  padding: 5px 10px 5px 10px;
}
.Lessonview_Chapter_List {
  display: block;
  font-size: 10px;
  padding-top: 10px;
  font-weight: bold;
  padding-left: 5px;
}
.Lessonview_Chapter_List span {
  color: #ffffff;
}
.Lesson_view_Video_List_Right {
  padding-top: 13px;
  padding-left: 1.3rem;
}
.Lesson_view_Video_List_Right span {
  font-size: 22px;
  color: #8b60fd;
  background-color: #fff;
  border-radius: 50%;
  padding: 0px 5px 4px 5px;
}
.Lesson_Video_column1 {
  background-color: #ffffff;
  box-shadow: 10px 14px 40px #fef9ef;
  border-radius: 10px;
  width: 90%;
  padding: 10px;
  margin-bottom: 1.6rem;
}
.Lessonview_Chapter_List1 time {
  color: #111111;
  opacity: 50%;
}
.Lessonview_Chapter_List1 {
  display: block;
  font-size: 10px;
  color: #111;
  padding-top: 10px;
  font-weight: bold;
  padding-left: 5px;
}
.Lesson_view_Video_List_Right1 img {
  margin-top: 1.5rem;
  width: 30px;
}
.Buttons_Lesson_View {
  margin-top: 2rem;
}
.Buttons_Lesson_View button {
  margin-right: 2rem;
  background-color: #fdf980;
  box-shadow: 0px 3px 6px #00000029;
  border: 2px solid #064974;
  border-radius: 12px;
  color: #064974;
  font-size: 17px;
  font-weight:500;
  width: 9rem;
  padding: 8px 10px;
  text-align: left;
}
#qandA_button{
  text-align: center;
  padding-right: 10px;
}
#qandA_button i{
  float: left;
  padding-left: 10px;
} 
.Buttons_Lesson_View button i {
  padding-right: 10px;
  font-size: 18px;
  font-weight: bold;
}
/* Glossary Data css */
.Glossary_Editor {
  background-color: #fef9ef;
  border: 1px solid #707070;
  border-radius: 30px;
  margin: 0 auto;
  width: 70%;
  height: 70%;
  z-index: 2;
  padding: 0px;
  position: absolute;
  top: 18%;
  right: 14%;
}
.Glossary_table {
  margin-top: 1rem;
}
.Glossary_close i {
  float: right;
  margin-right: 2rem;
  font-size: 18px;
  font-weight: bold;
  background: #fdf980 0% 0% no-repeat padding-box;
  box-shadow: 3px 3px 3px #00000029;
  border: 2px solid #fafafa;
  padding: 0px 3px 0px 4px;
  border-radius: 8px;
  cursor: pointer;
}
.Lessonview-glosary-dta .table {
  background-color: #fff;
  width: 80%;
  margin: 5rem 2rem 2rem 6.5rem;
  text-align: left;
}
.Lessonview-glosary-dta .table > thead th {
  background: #e0e0e0 0% 0% no-repeat padding-box;
  padding: 10px;
  box-shadow: 0px 3px 0px #f2f7ff80;
  color: #111;
  text-align: center;
}
.Lessonview-glosary-dta .table tbody,
tfoot,
tr,
td {
  height: 60px;
}
.Lessonview-glosary-dta .table > tbody tr td {
  font-size: 15px;
  /* border: 1px solid #918e96; */
  border-left: none;
  text-align: left;
  padding-left: 6rem;
  color: #918e96;
}
.Lessonview-glosary-dta .table > tbody tr td b {
  color: #064974;
}
.Lessonview-glosary-dta table {
  border-collapse: collapse;
  margin: 0 auto;
}
.Lessonview-glosary-dta table td {
  padding: 1rem;
  border: 1px solid #918e96;
}
.Lessonview-glosary-dta table tr:first-child td {
  border-top: 0;
}
.Lessonview-glosary-dta table tr td:first-child {
  border-left: 0;
}
.Lessonview-glosary-dta table tr:last-child td {
  border-bottom: 0;
}
.Lessonview-glosary-dta table tr td:last-child {
  border-right: 0;
}
/* Selftest Page Css  */
.postid-36782 {
  background-color: #ffe5dd;
  height: 44.6rem;
}
.Selftest_Wrapper {
  margin-top: 2rem;
  margin-left: 7.5rem;
}
.Selftest_Time button {
  background: #fdf980 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #0000000d;
  border: 2px solid #8b60fd;
  color: #e97838;
  padding: 5px 10px 5px 10px;
  border-radius: 8px;
}
.Selftest_Time button time {
  color: #111111;
  font-size: 14px;
}
.pagination {
  display: inline-block;
  text-align: center;
  margin-left: 5rem;
  /* margin-top: 0.5rem; */
}
.pagination a {
  width: 35px;
  line-height: 30px;
  text-align: center;
  border: 3px solid #8b60fd;
  display: inline-block;
  text-decoration: none;
  color: #8b60fd;
  border-radius: 50%;
  font-size: 14px;
  font-weight: bold;
  margin-left: 1rem;
}
.pagination a:hover {
  background-color: #8b60fd;
  color: white;
}
.pagination a.active {
  background-color: #8b60fd;
  color: white;
}
.Selftest_Back_button button {
  background: #fdf980 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #0000000d;
  border: 2px solid #8b60fd;
  color: #111111;
  padding: 5px 15px 5px 15px;
  border-radius: 8px;
}
.Selftest_body {
  margin-top: 2rem;
  text-align: center;
  background-color: #ffffff;
  height: 34rem;
  width: 90%;
  padding: 3rem 5rem;
  font-size: 18px;
  border-radius: 10px;
}
.Selftest_Question p {
  text-align: left;
}
.Selftest_Question p i {
  margin-left: 1rem;
  margin-right: 8rem;
  font-size: 25px;
}
.Selftest_Question h5 {
  text-align: left;
  margin-left: 12rem;
  background: #ffe5dd 0% 0% no-repeat padding-box;
  border: 1px solid #fafafa;
  width: fit-content;
  border-radius: 35px;
  padding: 8px 20px;
  font-size: 18px;
}
.Selftest_Question h6 {
  text-align: left;
  margin-left: 12.8rem;
  font-size: 18px;
}
.Selftest_Question img {
  margin-top: 1rem;
  margin-right: 5rem;
}
.Selftest_Options button {
  margin-bottom: 1rem;
  margin-right: 4rem;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 12px 19px #225a6f1a;
  border: 1px solid #b4ad9f;
  border-radius: 28px;
  font-size: 18px;
  width: 20%;
  height: 38px;
  text-align: left;
  padding-left: 2rem;
  color: #111111;
}
.Close_Button_Selftest button {
  float: right;
  margin-top: 2.5rem;
  background: #39311c 0% 0% no-repeat padding-box;
  border-radius: 49px;
  color: #ffffff;
  font-size: 18px;
  padding: 0px 10px;
  opacity: 1;
}
.Selftest_bottom_Chapter span {
  font-size: 10px;
  color: #111111;
  margin-top: 0.5rem;
}
/* QandA Component CSS */
.QandA_wrapper {
  /* margin: 2rem 4rem; */
  height: 100vh;
  padding-left: 4rem;
  padding-top: 1rem;
  padding-right: 4rem;
  /* padding-bottom: 2rem; */
  overflow-y: scroll;
}

.LeftSide_QandA h5 {
  font-size: 18px;
  color: #111111;
}
.RightSide_QandA {
  float: right;
}
.RightSide_QandA button {
  margin-left: 2rem;
  font-size: 13px;
}
#PostQuestion {
  border: 1px solid #ff6700;
  border-radius: 6px;
  color: #ff6700;
  background-color: #fef9ef;
  padding: 0.5rem 1rem;
  margin-right: 2rem;
}
#PostQuestion i {
  margin-left: 0.5rem;
  margin-right: 0.8rem;
}
#QandA_back {
  background: #fdf980 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #0000000d;
  border-radius: 6px;
  color: #111111;
  border: 1px solid #064974;
  padding: 0.5rem 1rem;
}
.QandA_Comment {
  background: #c6e4ce 0% 0% no-repeat padding-box;
  /* border-bottom: 1px solid rgb(180, 67, 67); */
  margin-top: 0.7rem;
  padding: 0rem 2rem 0rem 2rem;
}
.QandA_Comment p {
  font-size: 14px;
  width: 100%;
}
.QandA {
  width: 100%;
  height: auto;
}
.QandA__column {
  display: flex;
  align-items: center;
}
.QandA-Image {
  position: relative;
  bottom: 3.2rem;
  padding-right: 10px;
}
.QandA__info {
  width: auto;
}
.QandA__question {
  color: #111111;
  font-size: 14px;
  display: block;
  padding-bottom: 5px;
  font-weight: 700;
  padding-left: 2rem;
}
.QandA__answer {
  color: #918e96;
  font-size: 12px;
  display: flex;
  align-content: flex-start;
  padding-left: 2rem;
}

.QandA-uls_lis {
  padding-top: 10px;
}
.QandA-uls_lis p {
  font-size: 12px;
  color: #ff6700;
  font-weight: bolder;
  padding-left: 2rem;
}
.QandA-uls_lis p b {
  margin-left: 18rem;
  padding-right: 0.5rem;
}
.QandA-uls_lis p b,
span {
  font-size: 12px;
  font-weight: bolder;
  color: #111;
}
.QandA_tag1 p {
  color: #918e96;
}
.QandA-row {
  border-bottom: 1px solid #ebebeb;
  padding: 20px;
}
.QandA_HideandShow {
  margin: 0px 0px 10px 4.5rem;
  border: 1px solid#FEF9EF;
  padding: 15px;
  width: 80%;
  height: auto;
  background-color: #fef9ef;
}
.QandA-Image_left img {
  position: relative;
  top: 1.5rem;
}
.conents_left {
  background: #8b60fd5a 0% 0% no-repeat padding-box;
  border-radius: 10px;
  margin-left: 1rem;
  padding: 0.4rem 1.4rem;
  text-align: justify;
}
.conents_right {
  text-align: justify;
  margin-left: 30rem;
  background: #f7c7004d 0% 0% no-repeat padding-box;
  border-radius: 10px;
  margin-top: 2rem;
  padding: 2rem 3rem 0.8rem 1rem;
}
.QandA-Image_right img {
  position: absolute;
  right: 19rem;
  /* left:31.3rem;
  top:1.5rem; */
  padding: 0px 10px;
}
/* .QandA_HideandShow img {
  position: relative;
  top: 4.5rem;
} */
.QandA-Bottom_Data {
  padding-top: 10px;
}
.QandA-Bottom_Data p {
  font-size: 12px;
  color: #ff6700;
  font-weight: bolder;
  padding-left: 2rem;
}
.QandA-Bottom_Data p b {
  margin-left: 5rem;
  padding-right: 0.5rem;
}
.QandA-Bottom_Data p b,
span {
  font-size: 12px;
  font-weight: bolder;
  color: #424b54;
}
.QandA_comment .QandA__info {
  padding-left: 32px;
}
.QandA-textarea {
  margin: 20px 30px 10px 30px;
}
.Text_area {
  background: rgb(255, 255, 255);
  border-radius: 5px;
  width: 600px;
  margin-left: 15px;
  margin-right: 15px;
  border: 1px solid #53b9bc;
}
.Text_area input {
  background: none;
  border: none;
  font-family: "Segoe UI", sans-serif;
  outline: none;
  font-size: 0.9em;
  box-sizing: border-box;
  width: 100%;
}
.postFile_container {
  background-color: #fff;
  margin: 0 auto;
  width: 60%;
  height: 60%;
  z-index: 2;
  padding: 0px;
  position: absolute;
  top: 25%;
  right: 22%;
  border-radius: 20px;
}
.PostFile_Data {
  padding: 20px 10px 10px 10px;
}
.PostFile_Data p {
  margin-left: 23rem;
  color: #111111;
  font-size: 16px;
}
.PostFile_Data img {
  margin-left: 24rem;
}
.PostFile_Question textarea {
  border: 2px solid #8b60fd;
  margin: 0rem 0rem 0rem 3.7rem;
  margin-top: 1rem;
  padding: 10px;
  color: #999999;
}
.PostFile_Button button {
  margin-left: 26rem;
  margin-top: 1rem;
  background: #ff6700 0% 0% no-repeat padding-box;
  border-radius: 49px;
  color: #fff;
  border: none;
  padding: 0px 10px;
}
