.Contactus_Wrapper {
  padding-left: 7rem;
  background-color: #fff;
  height: 90vh;
  width: 100%;
}
.Contactus_Wrapper h5 {
  color: #3e4954;
  font-weight: bold;
  padding-top: 2rem;
  padding-left: 3rem;
}
.Contactus_Image img {
  width: 30rem;
  margin-top: 1.5rem;
  margin-left: 0.5rem;
}
.Contactus_Send {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 14px 40px #fef9ef;
  width: 80%;
  padding-bottom: 2rem;
}
.Contactus_Send h5 {
  font-size: 18px;
  letter-spacing: 0px;
  color: #3e4954;
}
.Contactus_Send label {
  font-size: 16px;
  letter-spacing: 0.5px;
  color: #064974;
  padding-left: 3rem;
  padding-top: 1rem;
}
.Contactus_Send input {
  background: #fef9ef 0% 0% no-repeat padding-box;
  border-radius: 8px;
  margin-left: 3rem;
  width: 70%;
  border: none;
  font-size: 12px;
  height: 3rem;
}
.Contactus_Send textarea {
  background: #fef9ef 0% 0% no-repeat padding-box;
  border-radius: 8px;
  margin-left: 3rem;
  width: 70%;
  border: none;
  font-size: 12px;
}
.Contactus_Send button {
  border: 1px solid #fafafa;
  border-radius: 49px;
  background-color: #ff6700;
  color: #fff;
  font-size: 12px;
  width: 7rem;
  margin-top: 2rem;
  margin-left: 3rem;
  height: 2rem;
}
.SendMessage_popUp {
  background-color: #fff;
  margin: 0 auto;
  width: 40%;
  height: 38%;
  z-index: 2;
  padding: 0px;
  position: absolute;
  top: 30%;
  right: 29%;
  border: 10px solid #8b60fd;
  border-radius: 20px;
}
.Tic_Image img {
  margin-left: 16rem;
  width: 25%;
  margin-top: 1.5rem;
}
.close_Icon img {
  float: right;
  margin-right: 1rem;
  margin-top: 1rem;
}
.SendMessage_popUp h6 {
  margin-top: 1rem;
  text-align: center;
  color: #424b54;
  font-size: 18px;
  font-weight: normal;
}
.SendMessage_popUp p {
  text-align: center;
  color: #666666;
  font-size: 12px;
}
